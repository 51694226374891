// Packages
import React from "react";
import dayjs from "dayjs";

// Utils
import { Cancel, Edit, Eye, ChevronRight, Complete, Lock } from "../../../../components";
import { ignoreTimeZone } from "../../../../utils";

function CustomFsrTable(props) {
    const CUSTOM_VIEW_BTN = {
        "render":
            <button
                style={{ "color": "black" }}
                className={"custom-view-fsr-btn"}
            >
                <span className="w3-tooltip">
                    <span className='tooltip-text w3-text w3-tag w-12'>
                        View
                    </span>
                    <Eye className='h-5 w-5' />
                </span>
            </button>
        ,
        "className": "custom-view-fsr-btn"
    };

    const CUSTOM_APPROVED_VIEW_BTN = {
        "render":
            <span
                style={{ "color": "black" }}
                className={"custom-view-fsr-btn"}
            >
                <span className="w3-tooltip">
                    <span className='tooltip-text w3-text w3-tag w-12'>
                        View
                    </span>
                    <Eye className='h-5 w-5' />
                </span>
            </span>
        ,
        "className": "custom-view-fsr-btn"
    };

    const CUSTOM_EDIT_BTN = {
        "render":
            <button
                style={{ "color": "black" }}
                className={"custom-edit-fsr-btn small-left-margin"}
            >
                <span className="w3-tooltip">
                    <span className='tooltip-text w3-text w3-tag w-12'>
                        Edit
                    </span>
                    <Edit className='h-5 w-5' />
                </span>
            </button>
        ,
        "className": "custom-edit-fsr-btn"
    };

    const CUSTOM_DELETE_BTN = {
        "render":
            <button
                style={{ "color": "black" }}
                className={"custom-delete-fsr-btn small-left-margin"}
            >
                <span className="w3-tooltip">
                    <span className='tooltip-text w3-text w3-tag w-12'>
                        Cancel
                    </span>
                    <Cancel className='h-5 w-5' />
                </span>
            </button>
        ,
        "className": "custom-delete-fsr-btn"
    };

    const CUSTOM_COMPLETE_BTN = {
        "render":
            <button
                style={{ "color": "white" }}
                className={"custom-delete-fsr-btn small-left-margin"}
            >
                <span className="w3-tooltip">
                    <span className='tooltip-text w3-text w3-tag w-12'>
                        Cancel
                    </span>
                    <Complete className='h-5 w-5' />
                </span>
            </button>
        ,
        "className": "custom-delete-fsr-btn"
    };

    const CUSTOM_LOCK_BTN = {
        "render":
            <button
                style={{ "color": "black" }}
                className={"custom-delete-fsr-btn small-left-margin"}
            >
                <span className="w3-tooltip">
                    <span className='tooltip-text w3-text w3-tag w-12'>
                        Lock
                    </span>
                    <Lock className='h-5 w-5' />
                </span>
            </button>
        ,
        "className": "custom-delete-fsr-btn"
    };

    return (
        <div className='custom-data-table react-table-lite-container'>
            <table className='react-table-lite-table' cellSpacing={0}>
                <thead className='react-table-lite-header'>
                    <tr>
                        <th></th>
                        {props?.headers?.map((h, index) => (
                            <th key={index}>
                                <div>{h?.replaceAll('_', ' ')}</div>
                            </th>
                        ))}                         
                        <th> Cost Plus </th>
                        <th> Block Hours </th>                       
                        <th> <div> Actions </div></th>
                    </tr>
                </thead>
                <tbody>
                    {props?.fsrList?.length < 1 && props?.isLoading
                        ? <tr>
                            <td colSpan={props?.headers?.length + 4}> <div>Loading data...</div> </td>
                        </tr>
                        : props?.fsrList?.length < 1 && !props?.isLoading
                            ? <tr><td colSpan={props?.headers?.length + 2}> <div>No data found</div></td></tr>
                            : null
                    }
                    {props?.fsrList && props?.fsrList?.map((fsr, index) => {
                        const row = <React.Fragment key={index}>
                            <tr className='react-table-lite-row'>
                                <td>
                                    {fsr?.flight_log !== 'Cancelled'
                                        && <div className='cursor-pointer' onClick={e => props?.toggleExpandFsr(fsr)}>
                                            <ChevronRight
                                                className={`w-4 h-4 ${props?.expandedFsrListIds?.find(id => id == fsr?.id) ? 'rotate-clock-wise-90' : ''}`}
                                            />
                                        </div>
                                    }
                                </td>
                                <td><div>{fsr?.id}</div></td>
                                <td><div>{fsr?.flight_number}</div></td>
                                <td><div>{fsr?.aircraft_tailnumber}</div></td>
                                <td><div>{fsr?.purpose_of_flight}</div></td>
                                <td><div>{dayjs(ignoreTimeZone(fsr?.createdat)).format('YYYY-MM-DD HH:mm')}</div></td>
                                <td>
                                    <div
                                        className={{
                                            'Draft': 'w3-text-grey',
                                            'Completed': 'text-success',
                                            'Cancelled': 'w3-text-red',
                                            'Confirmed': 'w3-text-blue',
                                            'Locked': 'w3-text-pink'
                                        }[fsr?.flight_log]}
                                    >
                                        {fsr?.flight_log}
                                    </div>
                                </td>
                                <td className='text-center'>
                                    {fsr?.costplus_generated
                                        ? <div className="bold text-success w3-large"> ✓ </div>
                                        : <div className="bold w3-text-red w3-large"> x </div>
                                    }

                                </td>
                                <td className='text-center'>
                                    {fsr?.blockhour_generated
                                        ? <div className="bold text-success w3-large"> ✓ </div>
                                        : <div className="bold w3-text-red w3-large"> x </div>
                                    }
                                </td>                                                                
                                <td className='rtl-table-actions'>
                                    <div className='rtl-action-btn-container flex'>
                                        {
                                            <span onClick={e => props?.routeToViewRequest(fsr)}>
                                                {CUSTOM_VIEW_BTN.render}
                                            </span>
                                        }
                                        {
                                            !fsr?.status?.toLowerCase()?.includes('complete') &&
                                                !fsr?.status?.toLowerCase()?.includes('locked') &&
                                                !fsr?.status?.toLowerCase()?.includes('cancel')
                                                ? <span onClick={e => props?.routeToEditRequest(fsr)}>
                                                    {CUSTOM_EDIT_BTN.render}
                                                </span>
                                                : null
                                        }
                                        {
                                            !fsr?.status?.toLowerCase()?.includes('complete') &&
                                                !fsr?.status?.toLowerCase()?.includes('locked') &&
                                                !fsr?.status?.toLowerCase()?.includes('cancel')
                                                ? <span onClick={e => props?.handleCancelRequest(fsr)}>
                                                    {CUSTOM_DELETE_BTN.render}
                                                </span>
                                                : null
                                        }
                                    </div>
                                </td>
                            </tr>
                            {
                                props?.expandedFsrListIds?.find(id => id == fsr?.id) &&
                                props?.approvedFsrList
                                    ?.filter(item => item?.request == fsr?.id)
                                    ?.map((approvedFsr, index) => (
                                        <tr className={`w3-pale-indigo react-table-lite-row ${index === 0 ? 'approved-row' : ''} `} key={index}>
                                            <td></td>
                                            <td><div>{`${fsr?.id}-${approvedFsr?.version}`}</div></td>
                                            <td><div>{approvedFsr?.flight_number}</div></td>
                                            <td><div>{approvedFsr?.aircraft_tailnumber}</div></td>
                                            <td><div>{approvedFsr?.purpose_of_flight}</div></td>
                                            <td><div>{dayjs(ignoreTimeZone(approvedFsr?.createdat)).format('YYYY-MM-DD HH:mm')}</div></td>
                                            <td><div>{approvedFsr?.status || approvedFsr?.flight_log}</div></td>                                            
                                             <td className="text-center">-</td>
                                            <td className="text-center">-</td>                                            
                                            <td className="rtl-table-actions">
                                                {
                                                    true
                                                        ? <div className='rtl-action-btn-container flex items-center'>
                                                            <span onClick={e => props?.routeToViewRequestHistory(approvedFsr)} className='cursor-pointer'>
                                                                {CUSTOM_APPROVED_VIEW_BTN.render}
                                                            </span>
                                                            {
                                                                approvedFsr?.status?.toLowerCase()?.includes('confirm') &&
                                                                    props?.isStaff
                                                                    ? <span onClick={e => props?.handleCompleteRequest(approvedFsr)}>
                                                                        {CUSTOM_COMPLETE_BTN.render}
                                                                    </span>
                                                                    : null
                                                            }
                                                            {
                                                                // !approvedFsr?.status?.toLowerCase()?.includes('complete') &&
                                                                props?.isStaff &&
                                                                !approvedFsr?.status?.toLowerCase()?.includes('locked') &&
                                                                    !approvedFsr?.status?.toLowerCase()?.includes('cancel') &&
                                                                    !approvedFsr?.status?.toLowerCase()?.includes('deactivated')
                                                                    ? <span onClick={e => props?.handleLockRequest(approvedFsr)}>
                                                                        {CUSTOM_LOCK_BTN.render}
                                                                    </span>
                                                                    : null
                                                            }
                                                        </div>
                                                        : null
                                                }
                                            </td>
                                        </tr>
                                    ))
                            }
                            {
                                props?.expandedFsrListIds?.find(id => id == fsr?.id) &&
                                    props?.loadingApprovedFsrList?.find(id => id == fsr?.id)
                                    ? <tr>
                                        <td className='w3-pale-indigo'></td>
                                        <td className='w3-pale-indigo' colSpan={props?.headers?.length + 4}>
                                            <div> Loading history... </div>
                                        </td>
                                    </tr>
                                    : null
                            }
                            {
                                props?.expandedFsrListIds?.find(id => id == fsr?.id) &&
                                    !props?.loadingApprovedFsrList?.find(id => id == fsr?.id) &&
                                    !props?.approvedFsrList?.find(item => item?.request == fsr?.id)
                                    ? <tr>
                                        <td className='w3-pale-indigo'></td>
                                        <td className='w3-pale-indigo' colSpan={props?.headers?.length + 4}>
                                            <div> No approved request history </div>
                                        </td>
                                    </tr>
                                    : null
                            }
                        </React.Fragment>;
                        return row;
                    })}
                </tbody>
            </table>
        </div>
    )
};

export default CustomFsrTable;