
// Packages
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import { useNavigate, useParams } from 'react-router-dom';

// APIs
import { BlockHourInvoiceDetailsRequest, BlockHourInvoiceEditRequest, FleetListRequest } from '../../../requests';

// Utils
import { auth, airbasesCache, fleetCache } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle } from '../../../utils';
import { AirportSelect, Loader, X } from '../../../components';
import dayjs from 'dayjs';

function BlockHourInvoicesForm() {
  const { invoiceId } = useParams();
  const Navigate = useNavigate();

  const INITIAL_FORM = {
    approve_request_id: '',
    contract_id: '',
    status: 'Draft',
    request_id: '',
    block_hour_invoice_item: []
  };

  const [isSubmitting, _isSubmitting] = useState(false);
  const [isLoading, _isLoading] = useState(invoiceId ? true : false);
  const [form, _form] = useState({ ...INITIAL_FORM });
  const [authState, _authState] = useAtom(auth);
  const [fleet, _fleet] = useAtom(fleetCache);
  const [airbases, _airbases] = useAtom(airbasesCache);

  useEffect(() => {
    if (invoiceId && authState) {
      getBlockHourInvoiceDetails();
    }
  }, [authState, invoiceId]);

  const getFleet = () => {
    const token = decodeString(authState);
    FleetListRequest(token)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((data) => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data && data.results) {
          // Keep server data in cache with current time
          _fleet({
            data: [...data.results],
            created: Date.now(),
          });
        } else {
          throw 'Request Failed';
        }
      })
      .catch((err) => {
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch fleet' });
      });
  };

  const getBlockHourInvoiceDetails = () => {
    _isLoading(true);
    const token = decodeString(authState);
    BlockHourInvoiceDetailsRequest(token, invoiceId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        _form({
          ...data,
          block_hour_invoice_item: data?.block_hour_invoice_item?.map(item => (
            {
              ...item,
              arrival_date_time: item?.arrival_date_time?.replace('Z', ''),
              departure_date_time: item?.departure_date_time?.replace('Z', ''),
              arrival_date: item?.arrival_date_time?.replace('Z', '')?.split('T')?.[0],
              arrival_time: item?.arrival_date_time?.replace('Z', '')?.split('T')?.[1]?.slice(0,5),
              departure_date: item?.departure_date_time?.replace('Z', '')?.split('T')?.[0],
              departure_time: item?.departure_date_time?.replace('Z', '')?.split('T')?.[1]?.slice(0,5),
            }
          ))
        });
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch blockhour details' });
        }
      )
  };

  // *********** Handlers ***********

  const handleBlockHourInvoiceFieldChange = (e, id) => {
    _form(old => ({
      ...old,
      block_hour_invoice_item: [...old?.block_hour_invoice_item]
        ?.map(item => {
          if (item?.id === id) {
            item[e.target.name] = e.target.value;
            if (e.target.name === "is_cancelled") {
              item[e.target.name] = e.target.checked;
            }
          }
          return item;
        })
    }));
  };

  const handleBlockHourInvoiceDepartureAirportChange = (iata, icao, id) => {
    _form(old => ({
      ...old,
      block_hour_invoice_item: [...old?.block_hour_invoice_item]
        ?.map(item => {
          if (item?.id === id) {
            item.departure_airport_iata = iata?.[0];
            item.departure_airport_icao = icao?.[0];
          }
          return item;
        })
    }));
  };

  const handleBlockHourInvoiceArrivalAirportChange = (iata, icao, id) => {
    _form(old => ({
      ...old,
      block_hour_invoice_item: [...old?.block_hour_invoice_item]
        ?.map(item => {
          if (item?.id === id) {
            item.arrival_airport_iata = iata?.[0];
            item.arrival_airport_icao = icao?.[0];
          }
          return item;
        })
    }));
  };

  const handleBlockHourInvoiceDateTimeChange = (e, name, id) => {
    _form(old => ({
      ...old,
      block_hour_invoice_item: [...old?.block_hour_invoice_item]
        ?.map(item => {
          if (item?.id === id) {
            item[name] = e.target.value;
          }
          return item;
        })
    }));
  };

  const handleFormatBlockHourInvoiceDateTime = (e, name, id) => {
    _form(old => ({
      ...old,
      block_hour_invoice_item: [...old?.block_hour_invoice_item]
        ?.map(item => {
          if (item?.id === id) {
            if (name === 'departure_date') {
              const date = dayjs(`${e.target.value} 00:00`)?.format('YYYY-MM-DD');
              const time = item?.departure_time || '00:00';
              item[name] = date;
              item['departure_date_time'] = date + 'T' + time + ":00";
            }
            else if (name === 'departure_time') {
              const time = dayjs(`2001-01-01 ${e.target.value}`)?.format('HH:mm');
              const date = item?.departure_date;
              item[name] = time;
              item['departure_date_time'] = date + 'T' + time + ":00";
            }
            else if (name === 'arrival_date') {
              const date = dayjs(`${e.target.value} 00:00`)?.format('YYYY-MM-DD');
              const time = item?.arrival_time || '00:00';
              item[name] = date;
              item['arrival_date_time'] = date + 'T' + time + ":00";
            }
            else if (name === 'arrival_time') {
              const time = dayjs(`2001-01-01 ${e.target.value}`)?.format('HH:mm');
              const date = item?.arrival_date;
              item[name] = time;
              item['arrival_date_time'] = date + 'T' + time + ":00";
            }
            const start = new Date(item['departure_date_time']?.replace('Z', ''));
            const end = new Date(item['arrival_date_time']?.replace('Z', ''));
            const diffInMs = end - start;
            let block_hours = diffInMs / (1000 * 60 * 60);
            block_hours = Math.round(block_hours * 100) / 100;            
            // const date1 = dayjs(item['departure_date_time']);
            // const date2 = dayjs(item['arrival_date_time']);
            // let block_hours = date1.diff(date2, 'minute');
            // block_hours = block_hours * -1 > 0 ? block_hours * -1 / 60 : 0;
            item["block_hours"] = block_hours;
            // item["total_cost"] = block_hours * Number(item?.unit_cost);
          }
          return item;
        })
    }));
  };

  const handleFormatBlockHourInvoiceField = (e, id) => {    
    _form(old => ({
      ...old,
      block_hour_invoice_item: [...old?.block_hour_invoice_item]
        ?.map(item => {
          if (item?.id === id) {
            item[e.target.name] = Number(e.target.value)?.toFixed(3) || 0;
            if(e.target.name === 'unit_cost'){
              item['total_cost'] = (Number(item?.block_hours) || 1) * Number(e.target.value)
            }
          }
          return item;
        })
    }));
  };

  const handleAddNewBlockHourInvoiceItem = (e, id) => {
    const block_hour_invoice_item = [...form?.block_hour_invoice_item];
    const new_block_hour_invoice_item = {
      ...block_hour_invoice_item?.[0],
      is_cancelled: false,
      id: `New${block_hour_invoice_item?.length + 1}`,
      departure_date_time: dayjs()?.format('YYYY-MM-DD') + 'T' + "00:00",
      arrival_date_time: dayjs()?.format('YYYY-MM-DD') + 'T' + "00:00",
      departure_date: dayjs()?.format('YYYY-MM-DD'),
      arrival_date: dayjs()?.format('YYYY-MM-DD'),
      departure_time: '00:00',
      arrival_time: '00:00',
      crew_count: 0,
      unit_cost: parseInt(block_hour_invoice_item?.[0]?.unit_cost),
      total_cost: parseInt(block_hour_invoice_item?.[0]?.unit_cost) * Number(block_hour_invoice_item?.[0]?.block_hours)
    };
    block_hour_invoice_item.unshift(new_block_hour_invoice_item);
    _form(old => ({
      ...old,
      block_hour_invoice_item
    }
    ));
  };

  const handleRemoveBlockHourInvoiceItem = (e, id) => {
    let block_hour_invoice_item = [...form?.block_hour_invoice_item];
    block_hour_invoice_item = block_hour_invoice_item?.filter(I => I?.id !== id);
    _form(old => ({
      ...old,
      block_hour_invoice_item
    }
    ));
  };

  const handleSubmit = () => {
    const token = decodeString(authState);
    const items_to_create = [...form?.block_hour_invoice_item]
      ?.filter(I => String(I?.id)?.indexOf('New') > -1)
      ?.map(I => { delete I.id; return I; });
    const items_to_delete = [...form?.block_hour_invoice_item]
      ?.filter(I => String(I?.id)?.indexOf('Del') > -1)
      ?.map(I => Number(I?.id?.replace(/[(A-Za-z)-]/g, '')));
    const block_hour_invoice_item = [...form?.block_hour_invoice_item]
      ?.filter(I => String(I?.id)?.indexOf('New') < 0 && String(I?.id)?.indexOf('Del') < 0);
    const dataToSubmit = {
      ...form,
      invoice_amount: form?.block_hour_invoice_item?.reduce((acc, item) => acc + (Number(item.total_cost) || 0), 0)?.toFixed(4),
      block_hour_invoice_item,
      items_to_create,
      items_to_delete
    };
    _form({ ...dataToSubmit });
    const data = JSON.parse(JSON.stringify(dataToSubmit));
    _isSubmitting(true);
    if (invoiceId) {
      BlockHourInvoiceEditRequest(token, JSON.stringify(data), invoiceId)
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            getFleet();
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'invoice updated' });
            Navigate("/block-hour-invoices");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
  };

  const handleRemoveBlockHourItem = (itemToDelete) => {
    _form(old => ({
      ...old,
      block_hour_invoice_item: old?.block_hour_invoice_item?.map(item => {
        if (item?.id === itemToDelete?.id) {
          item.id = `Del${item?.id}`;
        }
        return item;
      })
    }));
  };

  // *********** Render Functions ***********

  const BLOCK_HOUR_DETAILS = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Invoice Id </label>
          <input
            type='text'
            disabled={true}
            value={form?.id || ''}
            // value={form?.name}
            // onChange={e => handleFleetFieldChange('name', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin cursor-disable'
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> Request Id </label>
          <input
            type='text'
            disabled={true}
            value={form?.request_id}
            // onChange={e => handleFleetFieldChange('name', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin cursor-disable'
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> Aircraft </label>
          <input
            type='text'
            disabled={true}
            value={(form?.block_hour_invoice_item &&
              form?.block_hour_invoice_item?.find(item => item?.aircraft_tail_number)?.aircraft_tail_number
            ) || ''
            }
            // onChange={e => handleFleetFieldChange('name', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin cursor-disable'
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label className='no-opacity'> label </label>
          <div className='small-right-margin'>
            <button
              onClick={handleAddNewBlockHourInvoiceItem}
              className='small-right-margin w3-btn bg-primary-blue w3-text-white small-top-margin'
            >
              Add New Block Hours
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  const NEW_BLOCK_HOUR_INVOICE_ITEM_SECTION = (item) => (
    <div key={item?.id}>
      <X onClick={e => handleRemoveBlockHourInvoiceItem(e, item?.id)} className='cursor-pointer float-right mr-auto w-6 h-6 w3-text-red' />
      <div className='flex items-center flex-wrap wrap'>
        <div className='w-12'>From:</div>
        <AirportSelect
          placeholder='Search by airport'
          inputClass='no-focus w3-border'
          defaultValue={item?.departure_airport_icao + ' / ' + item?.departure_airport_iata}
          handleOptionSelect={(iata, icao) => handleBlockHourInvoiceDepartureAirportChange(iata, icao, item?.id)}
        />
      </div>
      <div className='flex items-center flex-wrap wrap small-top-margin'>
        <div className='w-12'>To:</div>
        <AirportSelect
          placeholder='Search by airport'
          inputClass='no-focus w3-border'
          defaultValue={item?.arrival_airport_icao + ' / ' + item?.arrival_airport_iata}
          handleOptionSelect={(iata, icao) => handleBlockHourInvoiceArrivalAirportChange(iata, icao, item?.id)}
        />
      </div>
      <div className='flex items-center flex-wrap wrap small-top-margin'>
        <div className='px-1'>
          <div className='small-right-margin'>Departure:</div>
          <div className='flex wrap'>
            <input
              type='date'
              name='departure_date'
              className='w3-input w-40'
              value={item?.departure_date}
              onBlur={e => handleFormatBlockHourInvoiceDateTime(e, 'departure_date', item?.id)}
              onChange={e => handleBlockHourInvoiceDateTimeChange(e, 'departure_date', item?.id)}
            />
            <input
              max={5}
              name='departure_time'
              className='w3-input w-40'
              value={item?.departure_time}
              onBlur={e => handleFormatBlockHourInvoiceDateTime(e, 'departure_time', item?.id)}
              onChange={e => handleBlockHourInvoiceDateTimeChange(e, 'departure_time', item?.id)}
            />
          </div>
        </div>
        <div className='px-1'>
          <div className='small-right-margin'>Arrival:</div>
          <div className='flex wrap'>
            <input
              type='date'
              name='arrival_date'
              className='w3-input w-40'
              value={item?.arrival_date}
              onBlur={e => handleFormatBlockHourInvoiceDateTime(e, 'arrival_time', item?.id)}
              onChange={e => handleBlockHourInvoiceDateTimeChange(e, 'arrival_date', item?.id)}
            />
            <input
              max={5}
              name='arrival_time'
              className='w3-input w-40'
              value={item?.arrival_time}
              onBlur={e => handleFormatBlockHourInvoiceDateTime(e, 'arrival_time', item?.id)}
              onChange={e => handleBlockHourInvoiceDateTimeChange(e, 'arrival_time', item?.id)}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const BLOCK_HOUR_INVOICE_ITEMS = () => (
    <React.Fragment>
      <ul className="small-top-margin list-details p-x-16">
        {form?.block_hour_invoice_item &&
          form?.block_hour_invoice_item
          ?.filter(item => String(item?.id)?.indexOf('Del') !== 0)
          ?.map((item, index) => (
            <li key={index} className={String(item?.id)?.indexOf('New') > -1 ? 'temp-cost-plus-invoice-item' : ''}>
              {String(item?.id)?.indexOf('New') < 0
                ? <div className="invoice-top-area flex items-center gap-20">
                  <div className="invoice-heading">
                    {`${item?.departure_airport_icao} / ${item?.departure_airport_iata} `}
                    →
                    {` ${item?.arrival_airport_icao} / ${item?.arrival_airport_iata}`}
                  </div>
                  <div>
                    <label className="w3-medium"> <strong>Departure:</strong> </label>
                    <input
                      type='date'
                      name='departure_date'
                      value={item?.departure_date}
                      onBlur={e => handleFormatBlockHourInvoiceDateTime(e, 'departure_date', item?.id)}
                      onChange={e => handleBlockHourInvoiceDateTimeChange(e, 'departure_date', item?.id)}
                      className={`block-hour-date-time-input w3-medium ${item?.block_hours < 0 ? 'error-field' : ''}`}
                    />
                    <input
                      max={5}
                      name='departure_time'
                      value={item?.departure_time}
                      onBlur={e => handleFormatBlockHourInvoiceDateTime(e, 'departure_time', item?.id)}
                      onChange={e => handleBlockHourInvoiceDateTimeChange(e, 'departure_time', item?.id)}
                      className={`block-hour-date-time-input w-24 w3-medium ${item?.block_hours < 0 ? 'error-field' : ''}`}
                    />
                  </div>
                  <div>
                    <label className="w3-medium"> <strong>Arrival:</strong> </label>
                    <input
                      type='date'
                      name='arrival_date'
                      value={item?.arrival_date}
                      onBlur={e => handleFormatBlockHourInvoiceDateTime(e, 'arrival_date', item?.id)}
                      onChange={e => handleBlockHourInvoiceDateTimeChange(e, 'arrival_date', item?.id)}
                      className={`block-hour-date-time-input w3-medium ${item?.block_hours < 0 ? 'error-field' : ''}`}
                    />
                    <input
                      max={5}
                      name='arrival_time'
                      value={item?.arrival_time}
                      onBlur={e => handleFormatBlockHourInvoiceDateTime(e, 'arrival_time', item?.id)}
                      onChange={e => handleBlockHourInvoiceDateTimeChange(e, 'arrival_time', item?.id)}
                      className={`block-hour-date-time-input w-24 w3-medium ${item?.block_hours < 0 ? 'error-field' : ''}`}
                    />
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id={'cancel' + item?.id}
                      name='is_cancelled'
                      checked={item?.is_cancelled}
                      onChange={e => handleBlockHourInvoiceFieldChange(e, item?.id)}
                    />
                    <label htmlFor={'cancel' + item?.id}> Mark Cancelled </label>
                  </div>
                </div>
                : NEW_BLOCK_HOUR_INVOICE_ITEM_SECTION(item)
              }
              <div className='flex py-2 gap-10 cost-grids'>
                <div className='field'>
                  <label className='w3-medium'> Block Hours </label>
                  <input
                    maxLength={4}
                    name='block_hours'
                    value={item?.block_hours}
                    onChange={e => handleBlockHourInvoiceFieldChange(e, item?.id)}
                    onBlur={e => handleFormatBlockHourInvoiceField(e, item?.id)}
                    className={`w3-input w3-border w3-round ${item?.block_hours < 0 ? 'error-field' : ''}`}
                  />
                </div>
                <div className='field'>
                  <label className='w3-medium'> Crew Count </label>
                  <input
                    maxLength={4}
                    name='crew_count'
                    value={item?.crew_count}
                    onChange={e => handleBlockHourInvoiceFieldChange(e, item?.id)}
                    onBlur={e => handleFormatBlockHourInvoiceField(e, item?.id)}
                    className='w3-input w3-border w3-round'
                  />
                </div>
                <div className='field'>
                  <label className='w3-medium'> Unit Cost </label>
                  <input
                    name='unit_cost'
                    value={item?.unit_cost}
                    onChange={e => handleBlockHourInvoiceFieldChange(e, item?.id)}
                    onBlur={e => handleFormatBlockHourInvoiceField(e, item?.id)}
                    className='w3-input w3-border w3-round'
                  />
                </div>
                <div className='field'>
                  <label className='w3-medium'> Total Cost </label>
                  <input
                    name='total_cost'
                    value={item?.total_cost}
                    onChange={e => handleBlockHourInvoiceFieldChange(e, item?.id)}
                    onBlur={e => handleFormatBlockHourInvoiceField(e, item?.id)}
                    className='w3-input w3-border w3-round'
                  />
                </div>
                <div className='field'>
                  <label className='w3-medium'> VAT % </label>
                  <input
                    name='vat_percentage'
                    value={item?.vat_percentage}
                    onChange={e => handleBlockHourInvoiceFieldChange(e, item?.id)}
                    onBlur={e => handleFormatBlockHourInvoiceField(e, item?.id)}
                    className='w3-input w3-border w3-round'
                  />
                </div>
                <div className='field'>
                  <div className='cost-remove-btn-holder'>
                    <label className='no-opacity w3-medium'> X </label>
                    <div
                      onClick={e => handleRemoveBlockHourItem(item)}
                      className='w3-button w3-pale-red small-left-margin'
                    >
                      <X className='w-5 h-5 w3-text-red' />
                    </div>
                  </div>
                </div>
                {/* <div className='field'>
                  <label className='w3-medium'>VAT Amount </label>
                  <input
                    maxLength={4}      
                    name="vat_amount"     
                    value={item?.vat_amount || 0}
                    onChange={e => handleBlockHourInvoiceFieldChange(e, item?.id)}       
                    className='w3-input w3-border w3-round'                    
                  />
                </div> */}
              </div>
            </li>
          ))}
      </ul>
    </React.Fragment>
  );

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const FORM_FOOTER = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <button
            disabled={isSubmitting}
            onClick={handleSubmit}
            className='w3-btn bg-primary-blue w3-text-white small-top-margin'
          >
            {isSubmitting ? 'Submitting' : 'Submit'}
          </button>
        </div>
      </div>
      <div className='h-2'></div>
    </React.Fragment>
  );

  const BLOCK_HOUR_FORM = () => (
    <div className='request-form-container w-full relative'>
      <span className='heading'> Block Hrs Invoice </span>
      <div className='border-primary-blue w3-round p-2'>
        {BLOCK_HOUR_DETAILS()}
        {BLOCK_HOUR_INVOICE_ITEMS()}
        {FORM_FOOTER()}
        {/* {BLOCK_HOUR_TOTAL_SECTION()} */}
      </div>
    </div>
  )

  const BLOCK_HOUR_TOTAL_SECTION = () => (
    <div className='request-form-container w-half relative py-2' style={{ float: 'right', width: '30%' }}>
      <span className='heading'> Block Hrs Invoice Total</span>
      <div className='border-primary-blue w3-round p-2'>
        <div className='flex py-2' style={{ justifyContent: 'flex-end' }}>
          <div>
            <label> Total Cost </label>
            <input
              onChange={e => 0}
              className='w3-input w3-border w3-round'
              value={
                (() => {
                  let sum = 0;
                  form?.block_hour_invoice_item
                  ?.filter(item => String(item?.id)?.indexOf('Del') !== 0)
                  ?.forEach((item) => {
                    sum += Number(item.total_cost) || 0;
                  });
                  return sum.toFixed(3);
                })()}
            // value={Number(item?.vat_amount).toFixed(3)}
            />
          </div>

        </div>
      </div>
    </div>
  )
  return (
    <div id='Invoice-Form'>
      {isLoading
        ? LOADER()
        : <>
          {BLOCK_HOUR_FORM()}
          {BLOCK_HOUR_TOTAL_SECTION()}
        </>
      }
    </div>
  )
}

export default BlockHourInvoicesForm;