// Packages
import React, { Fragment } from 'react'

// Utils
import { Loader } from '../../../../components';

export default function StandAloneHotelCostItemsTable({
  items,
  isLoading,
  onConfirmGenerateCostPlusInvoice
}) {  
  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const COST_ITEMS_LIST = () => (
    <table className='w3-table w3-striped'>
      <thead>
        <tr>
          <th> Hotel Id </th>
          <th> Costplus Id </th>
          <th> Amount </th>
        </tr>
      </thead>
      <tbody>
        {!items || !items?.length
          ? <tr><td colSpan={6}> No data found</td></tr>
          : null
        }
        {
          items &&
          items?.map((item, index) => (
            <Fragment key={index}>
              {item?.cost_plus && item?.cost_plus?.length
                ? item?.cost_plus?.map((cost_plus, index) => (
                  <tr key={index}>
                    <td>
                      <a className='cursor-pointer text-primary-blue w3-hover-text-blue' href={`/hotel-reservations/view/${item?.hotel_request?.id}`} target="_blank"> {item?.hotel_request?.id} </a>
                    </td>
                    <td>
                      <a className='cursor-pointer text-primary-blue w3-hover-text-blue' href={`/costplus-invoices/view/${cost_plus?.id}`} target="_blank"> {cost_plus?.id} </a>
                    </td>
                    <td>
                      <span> {cost_plus?.cost_plus_invoice_items?.find(invoice => invoice?.total_cost)?.total_cost || 0} </span>
                    </td>
                  </tr>
                ))
                : <tr>
                  <td>
                    <a className='cursor-pointer text-primary-blue w3-hover-text-blue' href={`/hotel-reservations/view/${item?.hotel_request?.id}`} target="_blank"> {item?.hotel_request?.id} </a>
                  </td>
                  <td>
                    <div className="flex flex-wrap items-center">
                      <span className='bold w3-text-red w3-large'>x</span>
                      <span>
                        <button
                          className='w3-tiny small-left-margin'
                          onClick={e => {
                            onConfirmGenerateCostPlusInvoice(item?.hotel_request?.id,
                              { aircraft_tail_number: item?.hotel_request?.ACFT_REG, aircraft_id: item?.hotel_request?.aircraft }
                            )
                          }}                          
                        >
                          Generate
                        </button>
                      </span>
                    </div>
                  </td>
                  <td> </td>
                </tr>
              }
            </Fragment>
          ))
        }
      </tbody>
    </table>
  );

  return (
    <div id="cost-items-list">
      {isLoading ? LOADER() : COST_ITEMS_LIST()}
    </div>
  );
};