// Packages
import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { Store } from "react-notifications-component";
import dayjs from "dayjs";

// APIs
import {
  AirbaseListRequest,
  AircraftByFleetListRequest,
  ContractsListRequest,
  FleetByAirbaseListRequest,
  FetchBillsBulkWebhookRequest,
  FetchCostItemsListRequest,
  FsrLockRequest,
  LegsByFsrListRequest,
  UpdateBillsBulkRequest,
  FetchStandaloneHotelCostItemsListRequest,
  FetchStandAloneHotelBillsBulkWebhookRequest,
  UpdateStandaloneHotelBillsBulkRequest,
  CostPlusInvoiceCreateRequest
} from "../../../requests";

// Utils
import { Modal, Warn } from "../../../components";
import { airbasesCache, auth, contractsCache } from "../../../atoms";
import { authenticationErrorHandle, constants, decodeString, setBillsListPdfContent } from "../../../utils";
import { FsrCateringBillsTable, FsrCateringCostItemsTable, FsrHotelBillsTable, FsrHotelCostItemsTable, StandAloneHotelCostItemsTable, StandAloneHotelbillsTable } from "./components";

export default function FetchBillsList(props) {
  const INITIAL_form = {
    airbase_ids: [],
    fleet_ids: [],
    aircraft_ids: [],
    contract: "",
    fleet: "",
    aircraft: "",
    airbase: "",
    month: 1,
    year: dayjs().format("YYYY"),
    service_type: "fsr_hotels",
  };

  const [isLoading, _isLoading] = useState(false);
  const [isSubmitting, _isSubmitting] = useState(false);
  const [isCostItemsFetched, _isCostItemsFetched] = useState(false);
  const [isOdooBillsFetched, _isOdooBillsFetched] = useState(false);

  const [form, _form] = useState({ ...INITIAL_form });
  const [authState, _authState] = useAtom(auth);
  const [contracts, _contracts] = useAtom(contractsCache);
  const [airbases, _airbases] = useAtom(airbasesCache);
  const [fleet, _fleet] = useState([]);
  const [aircrafts, _aircrafts] = useState([]);

  const [mappedBillItems, _mappedBillItems] = useState([]);
  const [odooBills, _odooBills] = useState([]);
  const [costItems, _costItems] = useState([]);
  const [markedDeleteItems, _markedDeleteItems] = useState([]);
  const [fsrLegs, _fsrLegs] = useState([]);

  const [showRequestLockModal, _showRequestLockModal] = useState(false);
  const [showConfirmSaveModal, _showConfirmSaveModal] = useState(false);
  const [showConfirmDeleteBillItemModal, _showConfirmDeleteBillItemModal] = useState(false);
  const [showConfirmHotelInvoiceCreateModal, _showConfirmHotelInvoiceCreateModal] = useState(false);
  const [selectedFSR, _selectedFSR] = useState(null);
  const [selectedHotelRequest, _selectedHotelRequest] = useState(null);
  const [selectedItem, _selectedItem] = useState(null);
  const [aircraftDetails, _aircraftDetails] = useState(null);

  // Get airbases & contracts either from cache or from server
  useEffect(() => {
    if (authState) {
      if (!contracts || !contracts.created || Date.now() - contracts.created >= 1200000) {
        getContracts();
      }
      if (!airbases || !airbases.created || Date.now() - airbases.created >= 1200000) {
        getAirbases();
      }
    }
  }, [authState]);

  useEffect(() => {
    if (form?.airbase) {
      getFleetByAirbase();
    }
  }, [form?.airbase]);

  useEffect(() => {
    if (form?.fleet) {
      getAircraftsByFleet();
    }
  }, [form?.fleet]);

  // Reset airbase, aircraft and fleet on contract change
  useEffect(() => {
    if (form?.contract) {
      _form((old) => ({
        ...old,
        airbase: "",
        fleet: "",
        aircraft: "",
      }));
    }
  }, [form?.contract]);

  // Reset aircraft and fleet on airbase change
  useEffect(() => {
    if (form?.airbase) {
      _form((old) => ({
        ...old,
        fleet: "",
        aircraft: "",
      }));
    }
  }, [form?.airbase]);

  // Reset aircraft on fleet change
  useEffect(() => {
    if (form?.fleet) {
      _form((old) => ({
        ...old,
        aircraft: "",
      }));
    }
  }, [form?.fleet]);

  // Map the bills array on change of cost items or odoo bills
  useEffect(() => {
    if (form?.service_type === 'fsr_hotels') mapFsrHotelBillAmount();
    else if (form?.service_type === 'standalone_hotels') mapStandAloneHotelBillAmount();
  }, [odooBills, costItems]);

  // set is_cost_items & is_odoo_bills_items fetched state to fale when aircraft, year or month changes
  useEffect(() => {
    _isCostItemsFetched(false);
    _isOdooBillsFetched(false);
    _markedDeleteItems([]);
    _mappedBillItems([]);
    _odooBills([]);
    _costItems([]);
  }, [form]);

  const getContracts = () => {
    const token = decodeString(authState);
    ContractsListRequest(token)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState("0"));
          return { errorCodes: constants.SESSIONTIMEOUT };
        } else return res.json();
      })
      .then((data) => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data && data.results) {
          // Keep server data in cache with current time
          _contracts({
            data: [...data.results],
            created: Date.now(),
          });
        } else {
          throw "Request Failed";
        }
      })
      .catch((err) => {
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch contracts" });
      });
  };

  const getAirbases = () => {
    const token = decodeString(authState);
    AirbaseListRequest(token)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState("0"));
          return { errorCodes: constants.SESSIONTIMEOUT };
        } else return res.json();
      })
      .then((nonPaginatedData) => {
        if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes))
          return;
        if (nonPaginatedData) {
          // Keep server data in cache with current time
          _airbases({
            data: [...nonPaginatedData],
            created: Date.now(),
          });
        } else {
          throw "Request Failed";
        }
      })
      .catch((err) => {
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch airbases" });
      });
  };

  const getFleetByAirbase = () => {
    const token = decodeString(authState);
    FleetByAirbaseListRequest(token, form?.airbase)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState("0"));
          return { errorCodes: constants.SESSIONTIMEOUT };
        } else return res.json();
      })
      .then((nonPaginatedData) => {
        if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes))
          return;
        if (nonPaginatedData) {
          // Keep server data in local state
          _fleet({
            data: [...nonPaginatedData],
          });
        } else {
          throw "Request Failed";
        }
      })
      .catch((err) => {
        console.error(err);
        Store.addNotification({
          ...constants.ERRORTOAST,
          message: "Failed to fetch fleet",
        });
      });
  };

  const getAircraftsByFleet = () => {
    const token = decodeString(authState);
    AircraftByFleetListRequest(token, form?.fleet)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState("0"));
          return { errorCodes: constants.SESSIONTIMEOUT };
        } else return res.json();
      })
      .then((nonPaginatedData) => {
        if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes))
          return;
        if (nonPaginatedData) {
          // Keep server data in local state
          _aircrafts({
            data: [...nonPaginatedData],
          });
        } else {
          throw "Request Failed";
        }
      })
      .catch((err) => {
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch aircrafts", });
      });
  };

  const getFsrCostItemsList = () => {
    const token = decodeString(authState);
    _isLoading(true);
    let data = { ...form };

    data.airbase_ids = data.airbase ? [parseInt(data.airbase)] : [];
    data.fleet_ids = data.fleet ? [parseInt(data.fleet)] : [];
    data.aircraft_ids = data.aircraft ? [parseInt(data.aircraft)] : [];
    data.contract = parseInt(data.contract) || "";
    data.aircraft = parseInt(data.aircraft) || "";
    data.fleet = parseInt(data.fleet) || "";
    data.month = parseInt(data.month) || "";
    data.year = parseInt(data.year) || "";
    data = JSON.stringify(data);
    FetchCostItemsListRequest(token, form?.contract, data)
      .then((res) => res.json())
      .then((data) => {
        const filteredFsrWithHotels = data?.filter(fsr => fsr?.hotel_mapping && fsr?.hotel_mapping?.length) || [];
        const newCostItemsArray = [];

        _isLoading(false);
        _isCostItemsFetched(true);
        _isOdooBillsFetched(false);
        _odooBills([]);
        _costItems([...filteredFsrWithHotels]);
        _mappedBillItems([...newCostItemsArray]);
      })
      .catch((err) => {
        _isLoading(false);
        _isCostItemsFetched(true);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch cost items", });
      });
  };

  const getStandAloneHotelCostItemsList = () => {
    const token = decodeString(authState);
    _isLoading(true);
    let data = { ...form };

    data.airbase_ids = data.airbase ? [parseInt(data.airbase)] : [];
    data.fleet_ids = data.fleet ? [parseInt(data.fleet)] : [];
    data.aircraft_ids = data.aircraft ? [parseInt(data.aircraft)] : [];
    data.contract = parseInt(data.contract) || "";
    data.aircraft = parseInt(data.aircraft) || "";
    data.fleet = parseInt(data.fleet) || "";
    data.month = parseInt(data.month) || "";
    data.year = parseInt(data.year) || "";
    data = JSON.stringify(data);
    FetchStandaloneHotelCostItemsListRequest(token, form?.contract, data)
      .then((res) => res.json())
      .then((data) => {
        const newCostItemsArray = [];

        _isLoading(false);
        _isCostItemsFetched(true);
        _isOdooBillsFetched(false);
        _odooBills([]);
        _costItems([...data]);
        _mappedBillItems([...newCostItemsArray]);
      })
      .catch((err) => {
        _isLoading(false);
        _isCostItemsFetched(true);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch standalone hotels", });
      });
  };

  const getFsrBillsList = () => {
    const token = decodeString(authState);
    let data = { ...form };
    data.airbase_ids = data.airbase ? [parseInt(data.airbase)] : [];
    data.fleet_ids = data.fleet ? [parseInt(data.fleet)] : [];
    data.aircraft_ids = data.aircraft ? [parseInt(data.aircraft)] : [];
    data.contract = parseInt(data.contract) || "";
    data.aircraft = parseInt(data.aircraft) || "";
    data.fleet = parseInt(data.fleet) || "";
    data.month = parseInt(data.month) || "";
    data.year = parseInt(data.year) || "";
    data = JSON.stringify(data);
    _isLoading(true);
    FetchBillsBulkWebhookRequest(token, form?.contract, data)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status && data?.bill_record) {
          _isLoading(false);
          _isOdooBillsFetched(true);
          _odooBills(data?.bill_record);
        } else {
          throw "Request Failed";
        }
      })
      .catch((err) => {
        console.error(err);
        _isLoading(false);
        _isOdooBillsFetched(false);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch bills" });
      });
  };

  const getStandAloneHotelsBillsList = () => {
    const token = decodeString(authState);
    let data = { ...form };
    data.airbase = parseInt(data.airbase) || "";
    data.contract = parseInt(data.contract) || "";
    data.aircraft = parseInt(data.aircraft) || "";
    data.fleet = parseInt(data.fleet) || "";
    data.month = parseInt(data.month) || "";
    data.year = parseInt(data.year) || "";
    data.airbase_ids = data.airbase ? [parseInt(data.airbase)] : [];
    data.fleet_ids = data.fleet ? [parseInt(data.fleet)] : [];
    data.aircraft_ids = data.aircraft ? [parseInt(data.aircraft)] : [];
    data = JSON.stringify(data);
    _isLoading(true);
    FetchStandAloneHotelBillsBulkWebhookRequest(token, form?.contract, data)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status && data?.bill_record) {
          _isLoading(false);
          _isOdooBillsFetched(true);
          _odooBills(data?.bill_record);
        } else {
          throw "Request Failed";
        }
      })
      .catch((err) => {
        console.error(err);
        _isLoading(false);
        _isOdooBillsFetched(false);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch bills" });
      });
  };

  const getFsrLegs = (fsrIds) => {
    const token = decodeString(authState);
    const data = JSON.stringify({ ids: [...fsrIds] });
    LegsByFsrListRequest(token, data)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
          throw 'Request Failed';
        } else return res.json();
      })
      .then(data => {
        if (data) {
          _fsrLegs(data);
        } else {
          throw 'Request Failed'
        }
      }).catch(err => {
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch request legs", });
      })
  };

  const mapStandAloneHotelBillAmount = () => {
    const map = new Map();
    let MappedBillItems = [];
    let FlatMappedCostListItems = [];
    let FlatMappedOdooBills = [];

    // Formatting Odoo bills for mapping
    odooBills.forEach(hotel => {
      hotel?.bills.forEach(bill => {
        const item = {
          hotel_id: parseInt(bill?.hotel_id) || undefined,
          bill_id: bill?.bill_id,
          amount_total: Number(bill?.amount_total),
          total_cost: Number(bill?.amount_total),
          ACFT_REG: costItems?.find(hotel => parseInt(hotel?.hotel_request?.id) === parseInt(bill?.hotel_id))?.hotel_request?.ACFT_REG || ''
        }
        FlatMappedOdooBills.push(item);
      })
    });

    // Formatting Cost invoice items for mapping
    costItems.forEach(hotel => {
      if (hotel?.cost_plus?.length) {
        hotel?.cost_plus?.forEach(costItem => {
          const item = {
            hotel_id: parseInt(hotel?.hotel_request?.id) || undefined,
            cost_plus_invoice_id: costItem?.id || undefined,
            cost_item_id: costItem?.cost_plus_invoice_items?.[0]?.id,
            total_cost: costItem?.cost_plus_invoice_items?.[0]?.total_cost,
            ACFT_REG: hotel?.hotel_request?.ACFT_REG
          }
          FlatMappedCostListItems.push(item);
        })
      } else {
        const item = {
          hotel_id: parseInt(hotel?.hotel_request?.id) || undefined,
          cost_plus_invoice_id: undefined,
          cost_item_id: undefined,
          total_cost: 0
        }
        FlatMappedCostListItems.push(item);
      }
    });

    FlatMappedCostListItems.forEach(item => {
      const key = `${item.hotel_id}`;
      if (!map.has(key)) {
        map.set(key, []);
      }
      map.get(key).push({ ...item });
    });

    // Create a dictionary of Hotel Ids to cost plus invoice ids to use in bills mapping
    const standAloneHotelCostInvoiceDictionary = FlatMappedCostListItems.reduce((acc, current) => {
      acc[current.hotel_id] = current.cost_plus_invoice_id;
      return acc;
    }, {});

    // Merge matching items from array2
    FlatMappedOdooBills.forEach(item => {
      const key = `${item.hotel_id}`;
      if (map.has(key)) {
        // Merge matched pairs
        map.get(key).forEach(matchedItem => {
          MappedBillItems.push({ ...matchedItem, ...item });
        });
        map.delete(key); // Remove from map after merging
      } else {
        MappedBillItems.push({ ...item });
      }
    });

    map.forEach(items => MappedBillItems.push(...items));
    MappedBillItems = MappedBillItems?.sort((a, b) => a?.hotel_id - b?.hotel_id);
    MappedBillItems = MappedBillItems?.map(item => {
      if (item?.bill_id && !item?.cost_plus_invoice_id) {
        item.cost_plus_invoice_id = standAloneHotelCostInvoiceDictionary[item?.hotel_id];
      } return item;
    });

    // Remove duplicate bill ids
    MappedBillItems = MappedBillItems.filter((value, index, self) => {
      // If the bill_id is null, undefined, or an empty string, we don't filter those out
      if (value.bill_id === null || value.bill_id === undefined || value.bill_id === "") {
        return true; // Keep the item
      }

      // Otherwise, filter out duplicates based on bill_id
      return index === self.findIndex((t) => t.bill_id === value.bill_id);
    });

    _mappedBillItems(MappedBillItems);

  };

  const mapFsrHotelBillAmount = () => {
    const map = new Map();
    let MappedBillItems = [];
    let FlatMappedCostListItems = [];
    let FlatMappedOdooBills = [];

    // Formatting Odoo bills for mapping
    odooBills.forEach(fsr => {
      fsr?.bills.forEach(bill => {
        const item = {
          fsr_id: parseInt(bill?.fsr_id) || undefined,
          bill_id: bill?.bill_id,
          amount_total: Number(bill?.amount_total),
          total_cost: Number(bill?.amount_total),
          arrival_airport: bill?.arrival_airport ? String(bill?.arrival_airport)?.trim() : '',
          departure_airport: bill?.departure_airport ? String(bill?.departure_airport)?.trim() : '',
          aircraft_tail_number: costItems?.find(C => parseInt(C?.fsr_id) === parseInt(bill?.fsr_id))?.aircraft_tail_number || ''
        }
        FlatMappedOdooBills.push(item);
      })
    });

    // Formatting Cost invoice items for mapping
    costItems.forEach(costInvoice => {
      costInvoice?.hotel_mapping.forEach(costItem => {
        const item = {
          aircraft_tail_number: costInvoice?.aircraft_tail_number,
          fsr_id: parseInt(costInvoice?.fsr_id) || undefined,
          cost_plus_invoice_id: costInvoice?.cost_plus_invoice_id,
          cost_item_id: costItem?.cost_item?.id,
          total_cost: costItem?.cost_item?.total_cost,
          approve_request_leg: costItem?.cost_item?.approve_request_leg,
          arrival_airport: `${costItem?.destination_airport_iata} / ${costItem?.destination_airport_icao}`?.trim(),
          departure_airport: `${costItem?.departure_airport_iata} / ${costItem?.departure_airport_icao}`?.trim(),
        }
        FlatMappedCostListItems.push(item);
      })
    });
    // console.log(FlatMappedCostListItems, FlatMappedOdooBills)

    FlatMappedCostListItems.forEach(item => {
      const key = `${item.fsr_id}-${item.departure_airport}-${item.arrival_airport}`;
      if (!map.has(key)) {
        map.set(key, []);
      }
      map.get(key).push({ ...item });
    });

    // Create a dictionary of Fsr Ids to cost plus invoice ids to use in bills mapping
    const fsrCostInvoiceDictionary = FlatMappedCostListItems.reduce((acc, current) => {
      acc[current.fsr_id] = current.cost_plus_invoice_id;
      return acc;
    }, {});

    // Merge matching items from array2
    FlatMappedOdooBills.forEach(item => {
      const key = `${item.fsr_id}-${item.departure_airport}-${item.arrival_airport}`;
      if (map.has(key)) {
        // Merge matched pairs
        map.get(key).forEach(matchedItem => {
          MappedBillItems.push({ ...matchedItem, ...item });
        });
        map.delete(key); // Remove from map after merging
      } else {
        MappedBillItems.push({ ...item });
      }
    });

    map.forEach(items => MappedBillItems.push(...items));
    MappedBillItems = MappedBillItems?.sort((a, b) => a?.fsr_id - b?.fsr_id);
    MappedBillItems = MappedBillItems?.map(item => {
      if (item?.bill_id && !item?.cost_plus_invoice_id) {
        item.cost_plus_invoice_id = fsrCostInvoiceDictionary[item?.fsr_id];
      } return item;
    });
    let fsrids = [];
    fsrids = MappedBillItems?.map(billItem => billItem?.fsr_id);
    fsrids = [...new Set(fsrids)];
    if (fsrids?.length) getFsrLegs(fsrids);

    _mappedBillItems(MappedBillItems);
  };

  // *********** Handlers ***********
  const handleChangeFilters = (e) => {
    _form((old) => ({
      ...old,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFetchBills = () => {
    if (!form?.contract) {
      Store.addNotification({
        ...constants.ERRORTOAST,
        message: "Select contract to fetch bills",
      });
    } else {
      if (form?.service_type === 'fsr_hotels') getFsrBillsList();
      else if (form?.service_type === 'standalone_hotels') getStandAloneHotelsBillsList();
    }
  };

  const handleFetchCostItems = () => {
    if (!form?.contract) {
      Store.addNotification({
        ...constants.ERRORTOAST,
        message: "Select contract to fetch bills",
      });
    } else {
      if (form?.service_type === 'fsr_hotels') getFsrCostItemsList();
      else if (form?.service_type === 'fsr_catering') getFsrCostItemsList();
      else if (form?.service_type === 'standalone_hotels') getStandAloneHotelCostItemsList();
    }
  }

  const handleBillAmountChange = (item, e) => {
    _mappedBillItems(old => (
      old?.map(bill => {
        if (bill?.bill_id === item?.bill_id) {
          bill.total_cost = Number(e.target.value) || "";
        } return bill;
      })
    ));
  };

  const handleAddMarkDelete = (item, e) => {
    _markedDeleteItems((old) => {
      return old.includes(item?.cost_item_id)
        ? old.filter(id => id !== item?.cost_item_id)
        : [...old, item?.cost_item_id];
    });
  };

  const handleChangeBillItemLeg = (item, e) => {
    const legInfo = JSON.parse(e.target.value);
    _mappedBillItems(old => (
      old?.map(bill => {
        if (bill?.bill_id === item?.bill_id) {
          bill.manuallySettedLeg = true;
          bill.approve_request_leg = legInfo?.id;
          bill.departure_airport = `${legInfo?.departure_airport_iata} / ${legInfo?.departure_airport_icao}`;
          bill.arrival_airport = `${legInfo?.destination_airport_iata} / ${legInfo?.destination_airport_icao}`;
        } return bill;
      })
    ));
  };

  const handleCloseConfirmDeleteBillItemModal = () => {
    _showConfirmDeleteBillItemModal(false);
    _selectedItem(null);
  };

  const handleShowConfirmSaveModal = (row) => {
    _showConfirmSaveModal(true);
  };

  const handleCloseConfirmSaveModal = () => {
    _showConfirmSaveModal(false);
  };

  const handleDeleteBillItem = () => {
    _mappedBillItems(old => (
      old?.filter(bill => bill?.temp_id !== selectedItem)
    ));
    handleCloseConfirmDeleteBillItemModal();
  };

  const handleDownloadPdf = () => {
    let data = document.getElementById('bills-data').innerHTML;
    const content = `<div style='padding: 12px; margin: 20px 5px;'> ${data} </div>`;
    const PDFContent = setBillsListPdfContent(content, 'Bills');
    const printWindow = window.open('', '', 'height=1024,width=900,toolbar=no,menubar=no');
    printWindow.document.write(PDFContent);
    printWindow.document.close();
    printWindow.print();
  };

  const handleOpenRequestLockModal = (fsrId) => {
    _selectedFSR(fsrId);
    _showRequestLockModal(true);
  };

  const handleCloseRequestLockModal = () => {
    _selectedFSR(null);
    _showRequestLockModal(false);
  };

  const handleOpenHotelInvoiceCreateModal = (hotelId, aircraftDetails) => {
    _selectedHotelRequest(hotelId);
    _aircraftDetails(aircraftDetails);
    _showConfirmHotelInvoiceCreateModal(true);
  };

  const handleCloseHotelInvoiceCreateModal = () => {
    _aircraftDetails(null);
    _selectedHotelRequest(null);
    _showConfirmHotelInvoiceCreateModal(false);
  };

  const handleConfirmFsrLock = () => {
    const token = decodeString(authState);
    _isSubmitting(true);
    const statusChanged = { status: 'Locked' };
    const JSONStringData = JSON.stringify(statusChanged);
    FsrLockRequest(token, JSONStringData, selectedFSR)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
          throw 'Request Failed';
        } else return res.json();
      })
      .then((data) => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if ((typeof data === 'string' && data.indexOf('Error') > -1)) {
          throw "Request Failed";
        } else {
          _isSubmitting(false);
          getFsrCostItemsList();
          handleCloseRequestLockModal();
          Store.addNotification({ ...constants.SUCCESSTOAST, message: "Invoice created" });
        }
      })
      .catch((err) => {
        _isSubmitting(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Request failed" });
      });
  };

  const handleConfirmHotelInvoiceCreate = () => {
    const token = decodeString(authState);
    _isSubmitting(true);
    const data = {};
    data.cost_plus_invoice = {
      invoice_amount: 0,
      invoice_type:	'HOTEL',
      request_id: selectedHotelRequest
    };
    data.cost_plus_invoice_item = [
      { total_cost: 0, service_name: 'HOTEL', quantity: 1, ...aircraftDetails }
    ];
    CostPlusInvoiceCreateRequest(token, JSON.stringify(data))
      .then(res => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
          throw 'Request Failed';
        } else return (res.json())
      })
      .then(data => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
          throw 'Request Failed';
        } else {
          _isSubmitting(false);
          getStandAloneHotelCostItemsList();
          handleCloseHotelInvoiceCreateModal();
          Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Invoice created' });
        }
      })
      .catch(err => {
        _isSubmitting(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
      });
  };

  const handleSaveBills = () => {
    let itemsToUpdate = mappedBillItems?.filter?.(item => item?.cost_plus_invoice_id && item?.bill_id)?.filter(item => item?.cost_item_id)?.filter?.(item => !markedDeleteItems?.includes(item?.cost_item_id));
    let itemsToCreate = mappedBillItems?.filter?.(item => item?.cost_plus_invoice_id && item?.bill_id)?.filter(item => !item?.cost_item_id);
    let itemsToDelete = mappedBillItems?.filter?.(item => markedDeleteItems?.includes(item?.cost_item_id))?.map(item => item?.cost_item_id);
    _isSubmitting(true);
    const token = decodeString(authState);
    if (form?.service_type !== 'standalone_hotels') {
      const JSONStringData = JSON.stringify({
        items_to_create: itemsToCreate,
        items_to_update: itemsToUpdate,
        items_to_delete: itemsToDelete
      });
      console.log(JSONStringData);
      UpdateBillsBulkRequest(token, JSONStringData)
        .then((res) => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return res.json();
        })
        .then((data) => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof data === 'string' && data.indexOf('Error') > -1)) {
            throw "Request Failed";
          } else {
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: "Bills amount saved" });
          }
        })
        .catch((err) => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: "Request failed" });
        })
        .finally(() => {
          handleCloseConfirmSaveModal();
        });
    } else {
      const JSONStringData = JSON.stringify({
        items_to_update: itemsToUpdate
      });
      UpdateStandaloneHotelBillsBulkRequest(token, JSONStringData)
        .then((res) => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return res.json();
        })
        .then((data) => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof data === 'string' && data.indexOf('Error') > -1)) {
            throw "Request Failed";
          } else {
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: "Bills amount saved" });
          }
        })
        .catch((err) => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: "Request failed" });
        })
        .finally(() => {
          handleCloseConfirmSaveModal();
        });
    }
  };

  // *********** Render Functions ***********

  const FILTERS = () => (
    <div>
      <div className="w3-row-padding w3-stretch">
        <div className="w3-col s12 m4">
          <label className="w3-medium" htmlFor="contract">
            Contract
          </label>
          <select
            id="contract"
            name="contract"
            value={form?.contract}
            onChange={handleChangeFilters}
            className="w3-input w3-white small-top-margin w3-border w3-round small-top-margin small-bottom-margin"
          >
            <option value={""} disabled>
              Select Contract
            </option>
            {contracts &&
              contracts?.data &&
              contracts?.data?.map((F, index) => (
                <option value={F.id} key={index}>
                  {F.name}
                </option>
              ))}
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className="w3-medium" htmlFor="contract">
            Airbase
          </label>
          <select
            id="airbase"
            name="airbase"
            value={form?.airbase}
            onChange={handleChangeFilters}
            className="w3-input w3-white small-top-margin w3-border w3-round small-top-margin small-bottom-margin"
          >
            <option value={""} disabled>
              Select Airbase
            </option>
            {airbases &&
              form?.contract &&
              airbases?.data &&
              airbases?.data?.filter(Ab => Ab?.contract == form?.contract)
                ?.map((Ab, index) => (
                  <option value={Ab.id} key={index}>
                    {Ab.name}
                  </option>
                ))}
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className="w3-medium" htmlFor="contract">
            Fleet
          </label>
          <select
            id="fleet"
            name="fleet"
            value={form?.fleet}
            onChange={handleChangeFilters}
            className="w3-input w3-white small-top-margin w3-border w3-round small-top-margin small-bottom-margin"
          >
            <option value={""} disabled>
              Select Fleet
            </option>
            {fleet &&
              fleet?.data &&
              fleet?.data?.map((Fl, index) => (
                <option value={Fl.id} key={index}>
                  {Fl.name}
                </option>
              ))}
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className="w3-medium" htmlFor="contract">
            Aircraft
          </label>
          <select
            id="aircraft"
            name="aircraft"
            value={form?.aircraft}
            onChange={handleChangeFilters}
            className="w3-input w3-white small-top-margin w3-border w3-round small-top-margin small-bottom-margin"
          >
            <option value={""} disabled>
              Select Aircraft
            </option>
            {aircrafts &&
              aircrafts?.data &&
              aircrafts?.data?.map((A, index) => (
                <option value={A.id} key={index}>
                  {A.tailnumber}
                </option>
              ))}
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className="w3-medium" htmlFor="contract">
            Service Type
          </label>
          <select
            id="service_type"
            name="service_type"
            value={form?.service_type}
            onChange={handleChangeFilters}
            className="w3-input w3-white small-top-margin w3-border w3-round small-top-margin small-bottom-margin"
          >
            <option value={"fsr_hotels"}> Hotel with FSR </option>
            <option value={"standalone_hotels"}> Standalone Hotels </option>
            {/* <option value={"fsr_catering"}> Catering with FSR </option> */}
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className="w3-medium" htmlFor="contract">
            Month
          </label>
          <select
            id="month"
            name="month"
            value={form?.month}
            onChange={handleChangeFilters}
            className="w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin"
          >
            <option value={""} disabled>
              Select Month
            </option>
            {constants?.MONTHS?.map((option, index) => (
              <option key={option} value={index + 1}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className="w3-medium" htmlFor="contract">
            Year
          </label>
          <select
            id="year"
            name="year"
            value={form?.year}
            onChange={handleChangeFilters}
            className="w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin"
          >
            <option value={""} disabled>
              Select Year
            </option>
            {Array.from(
              { length: parseInt(dayjs().year()) - 2024 + 1 },
              (_, i) => i + 2024
            ).map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>
        </div>

        <div className="w3-col s12 m8">
          <label className="w3-medium no-opacity"> Year </label>
          <div className="flex justify-end wrap small-top-margin">
            {
              isCostItemsFetched
                ? <button
                  disabled={isLoading}
                  onClick={handleFetchBills}
                  className="w3-btn small-top-margin bg-primary-blue w3-text-white small-left-margin"
                >
                  Fetch Bills
                </button>
                : <button
                  disabled={isLoading}
                  onClick={handleFetchCostItems}
                  className="w3-btn small-top-margin bg-primary-blue w3-text-white small-left-margin"
                >
                  Fetch Invoice Amounts
                </button>
            }
            {
              isCostItemsFetched && odooBills?.length
                ? <button onClick={handleShowConfirmSaveModal} className="w3-btn small-top-margin bg-primary-blue w3-text-white small-left-margin">
                  Save Bills
                </button>
                : null
            }
            <button onClick={handleDownloadPdf} className="w3-btn small-top-margin bg-primary-blue w3-text-white small-left-margin">
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const REQUEST_LOCK_MODAL = () => (
    <Modal isOpen={showRequestLockModal} modalClass="w-fit w3-round-medium">
      <div className="w-full">
        <div className='w3-text-pink w3-pale-red py-1 px-2 flex flex-wrap items-center'>
          <Warn className='w-6 h-6 small-right-margin' />
          <h3>
            Generate Cost Invoice
          </h3>
        </div>
        <div className='py-1 px-2'>
          <React.Fragment>
            Generating cost plus invoice for
            <span className='bold'>{` Request ID ${selectedFSR}`}</span>
            <span> will mark it as locked </span>
            <p> Confirm to generate cost plus invoice </p>
          </React.Fragment>
          <div className="h-3"></div>
          <div className="flex justify-end">
            <button onClick={handleCloseRequestLockModal} className='w3-btn w3-grey w3-text-white'> Close </button>
            <button onClick={handleConfirmFsrLock} disabled={isSubmitting} className='w3-btn w3-pink w3-text-white small-left-margin'>
              {isSubmitting ? 'Processing' : 'Confirm'}
            </button>
          </div>
          <div className="h-3"></div>
        </div>
      </div>
    </Modal>
  );

  const HOTEL_REQUEST_INVOICE_CREATE_MODAL = () => (
    <Modal isOpen={showConfirmHotelInvoiceCreateModal} modalClass="w-fit w3-round-medium">
      <div className="w-full">
        <div className='w3-text-pink w3-pale-red py-1 px-2 flex flex-wrap items-center'>
          <Warn className='w-6 h-6 small-right-margin' />
          <h3>
            Generate Cost Invoice
          </h3>
        </div>
        <div className='py-1 px-2'>
          <React.Fragment>
            Generating cost plus invoice for
            <span className='bold'>{` Hotel ID ${selectedHotelRequest}`}</span>
            <p> Confirm to generate cost plus invoice </p>
          </React.Fragment>
          <div className="h-3"></div>
          <div className="flex justify-end">
            <button onClick={handleCloseHotelInvoiceCreateModal} className='w3-btn w3-grey w3-text-white'> Close </button>
            <button onClick={handleConfirmHotelInvoiceCreate} disabled={isSubmitting} className='w3-btn w3-pink w3-text-white small-left-margin'>
              {isSubmitting ? 'Processing' : 'Confirm'}
            </button>
          </div>
          <div className="h-3"></div>
        </div>
      </div>
    </Modal>
  );

  const CONFIRM_SAVE_MODAL = () => {
    let itemsToUpdate = mappedBillItems?.filter?.(item => item?.cost_plus_invoice_id && item?.bill_id)?.filter(item => item?.cost_item_id)?.filter?.(item => !markedDeleteItems?.includes(item?.cost_item_id));
    let itemsToCreate = mappedBillItems?.filter?.(item => item?.cost_plus_invoice_id && item?.bill_id)?.filter(item => !item?.cost_item_id);
    let itemsToDelete = mappedBillItems?.filter?.(item => markedDeleteItems?.includes(item?.cost_item_id));
    let UPDATED = itemsToUpdate?.map((item, index) =>
      <li key={index}>
        <span>{`Cost Plus Invoice: ${item?.cost_plus_invoice_id}`}</span>
        {form?.service_type !== "standalone_hotels"
          && <span className="small-left-margin">{`Leg: ${item?.departure_airport} - ${item?.arrival_airport}`}</span>
        }
        <span className="small-left-margin">{`Total Amount: ${Number(item?.total_cost)?.toFixed(2)}`}</span>
      </li>
    );
    let CREATED = itemsToCreate?.map((item, index) =>
      <li key={index}>
        <span>{`Cost Plus Invoice: ${item?.cost_plus_invoice_id || 'Null'}`}</span>
        {form?.service_type !== "standalone_hotels"
          && <span className="small-left-margin">{`Leg: ${item?.departure_airport ? item?.departure_airport + " - " + item?.arrival_airport : 'Null'}`}</span>
        }
        <span className="small-left-margin">{`Total Amount: ${Number(item?.total_cost)?.toFixed(2)}`}</span>
      </li>
    );
    let DELETED = itemsToDelete?.map((item, index) =>
      <li key={index}>
        <span>{`Cost Plus Invoice: ${item?.cost_plus_invoice_id}`}</span>
        {form?.service_type !== "standalone_hotels"
          && <span className="small-left-margin">{`Leg: ${item?.departure_airport} - ${item?.arrival_airport}`}</span>
        }
        <span className="small-left-margin">{`Total Amount: ${Number(item?.total_cost)?.toFixed(2)}`}</span>
      </li>
    );
    return (
      <Modal isOpen={showConfirmSaveModal} modalClass="w-fit w3-round-medium">
        <div className="w-full">
          <div className='w3-text-blue w3-pale-blue py-1 px-2 flex flex-wrap items-center'>
            <Warn className='w-6 h-6 small-right-margin' />
            <h3>
              Confirm Save
            </h3>
          </div>
          <div className='py-1 px-2'>
            <React.Fragment>
              Are you sure you want to save?
            </React.Fragment>
            <div className="h-3"></div>
            {itemsToUpdate?.length
              ? <div> <strong> Update Items </strong></div>
              : null
            }
            <div className="w3-section">
              <ul className="no-style-list w3-medium w3-text-purple"> {UPDATED} </ul>
            </div>
            {itemsToCreate?.length
              ? <div> <strong> Create Items </strong></div>
              : null
            }
            <div className="w3-section">
              <ul className="no-style-list w3-medium w3-text-green"> {CREATED} </ul>
            </div>
            {itemsToDelete?.length
              ? <div> <strong> Delete Items </strong></div>
              : null
            }
            <div className="w3-section">
              <ul className="no-style-list w3-medium w3-text-red"> {DELETED} </ul>
            </div>
            <div className="flex justify-end">
              <button onClick={handleCloseConfirmSaveModal} className='w3-btn w3-grey w3-text-white'> Close </button>
              <button onClick={handleSaveBills} disabled={false} className='w3-btn w3-blue w3-text-white small-left-margin'>
                {isSubmitting ? 'Processing' : 'Confirm'}
              </button>
            </div>
            <div className="h-3"></div>
          </div>
        </div>
      </Modal>
    )
  };

  const CONFIRM_DELETE_ITEM_MODAL = () => (
    <Modal isOpen={showConfirmDeleteBillItemModal} modalClass="w-fit w3-round-medium">
      <div className="w-full">
        <div className='w3-text-red w3-pale-red py-1 px-2 flex flex-wrap items-center'>
          <Warn className='w-6 h-6 small-right-margin' />
          <h3>
            Don't Save
          </h3>
        </div>
        <div className='py-1 px-2'>
          <React.Fragment>
            Are you sure you want to remove this cost item?
          </React.Fragment>
          <div className="h-3"></div>
          <div className="flex justify-end">
            <button onClick={handleCloseConfirmDeleteBillItemModal} className='w3-btn w3-grey w3-text-white'> Close </button>
            <button onClick={handleDeleteBillItem} className='w3-btn w3-red w3-text-white small-left-margin'>
              Confirm
            </button>
          </div>
          <div className="h-3"></div>
        </div>
      </div>
    </Modal>
  );

  const ITEMS_LIST = () => {
    if (form?.service_type === 'fsr_hotels' && isOdooBillsFetched && isCostItemsFetched) {
      return (
        <div id="bills-data">
          <FsrHotelBillsTable
            isLoading={isLoading}
            fsrLegs={fsrLegs}
            items={mappedBillItems}
            markedDeleteItems={markedDeleteItems}
            onSetLeg={handleChangeBillItemLeg}
            onAddMarkDelete={handleAddMarkDelete}
            onAmountChange={handleBillAmountChange}
          />
        </div>
      )
    }
    else if (form?.service_type === 'fsr_hotels') {
      return (
        <div id="bills-data">
          <FsrHotelCostItemsTable
            onConfirmGenerateCostPlusInvoice={handleOpenRequestLockModal}
            isLoading={isLoading}
            items={costItems}
          />
        </div>
      )
    } else if (form?.service_type === 'fsr_catering' && isOdooBillsFetched && isCostItemsFetched) {
      return (
        <div id="bills-data">
          <FsrCateringBillsTable
            isLoading={isLoading}
            fsrLegs={fsrLegs}
            items={mappedBillItems}
            markedDeleteItems={markedDeleteItems}
            onSetLeg={handleChangeBillItemLeg}
            onAddMarkDelete={handleAddMarkDelete}
            onAmountChange={handleBillAmountChange}
          />
        </div>
      )
    } else if (form?.service_type === 'fsr_catering') {
      return (
        <div id="bills-data">
          <FsrCateringCostItemsTable
            isLoading={isLoading}
            items={costItems}
          />
        </div>
      )
    } else if (form?.service_type === 'standalone_hotels' && isOdooBillsFetched && isCostItemsFetched) {
      return (
        <div id="bills-data">
          <StandAloneHotelbillsTable
            isLoading={isLoading}
            items={mappedBillItems}
            markedDeleteItems={markedDeleteItems}
            onAddMarkDelete={handleAddMarkDelete}
            onAmountChange={handleBillAmountChange}
          />
        </div>
      )
    } else if (form?.service_type === 'standalone_hotels') {
      return (
        <div id="bills-data">
          <StandAloneHotelCostItemsTable
            items={costItems}
            isLoading={isLoading}
            onConfirmGenerateCostPlusInvoice={handleOpenHotelInvoiceCreateModal}
          />
        </div>
      )
    }
  };

  const CONTENT = () => (
    <div className="page-content w3-white h-full relative overflow-hidden">
      <div className="w-full p-2">
        {FILTERS()}
        {ITEMS_LIST()}
        {showConfirmSaveModal && CONFIRM_SAVE_MODAL()}
        {showRequestLockModal && selectedFSR && REQUEST_LOCK_MODAL()}
        {showConfirmHotelInvoiceCreateModal && selectedHotelRequest && HOTEL_REQUEST_INVOICE_CREATE_MODAL()}
        {showConfirmDeleteBillItemModal && selectedItem && CONFIRM_DELETE_ITEM_MODAL()}
      </div>
    </div>
  );

  return <div>{CONTENT()}</div>;
}