// Packages
import React, { Fragment } from 'react'

// Utils
import { Loader } from '../../../../components';

export default function FsrCateringCostItemsTable({
  items,
  isLoading,
}) {

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const COST_ITEMS_LIST = () => (
    <table className='w3-table w3-striped'>
      <thead>
        <tr>
          <th> FSR Id </th>
          <th> Costplus Id </th>
          <th> Aircraft </th>
          <th> Leg </th>
          <th> Amount </th>
        </tr>
      </thead>
      <tbody>
        {!items || !items?.length
          ? <tr><td colSpan={6}> No data found</td></tr>
          : null
        }
        {
          items &&
          items?.map((item, index) => (
            <Fragment key={index}>
              {
                item?.hotel_mapping?.map((catering, index) => (
                  <tr key={index}>
                    <td>
                      <a className='cursor-pointer text-primary-blue w3-hover-text-blue' href={`/requests/view/${item?.fsr_id}`} target="_blank"> {item?.fsr_id} </a>
                    </td>
                    <td>
                      {
                        item?.cost_plus_invoice_id
                          ? <a className='cursor-pointer text-primary-blue w3-hover-text-blue' href={`/costplus-invoices/view/${item?.cost_plus_invoice_id}`} target="_blank"> {item?.cost_plus_invoice_id} </a>
                          : <div className="bold w3-text-red w3-large"> x </div>
                      }
                    </td>
                    <td>
                      {item?.aircraft_tail_number}
                    </td>
                    <td>
                      {`${catering?.departure_airport_iata} / ${catering?.departure_airport_icao}`?.trim()}
                      &nbsp;-&nbsp;
                      {`${catering?.destination_airport_iata} / ${catering?.destination_airport_icao}`?.trim()}
                    </td>
                    <td>
                      {(catering?.cost_item && catering?.cost_item?.total_cost)}
                    </td>
                  </tr>
                ))
              }
            </Fragment>
          ))
        }
      </tbody>
    </table>
  );

  return (
    <div id="cost-items-list">
      {isLoading ? LOADER() : COST_ITEMS_LIST()}
    </div>
  );
};