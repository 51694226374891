// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function Dropdown(props) {

  const [showMenu, _showMenu] = useState(false);

  // Handlers
  const handleToggleDropDown = (e) => {
    _showMenu(old => !old);
  };

  return (
    <div className={`w3-dropdown-click ${props.containerClass}`}>
      <button onClick={handleToggleDropDown} className={`w3-btn ${props?.buttonClass}`}>
        {props?.buttonText}
      </button>
      <div className={`w3-dropdown-content w3-bar-block w3-border ${props?.menuClass} ${showMenu ? 'w3-show' : ''}`}>
        {props?.children}
      </div>
    </div>
  )
}

Dropdown.propTypes = {
  containerClass: PropTypes.string,
  buttonText: PropTypes.string,
  buttonClass: PropTypes.string,
  menuClass: PropTypes.string,
  handleCloseDropDown: PropTypes.func
};

Dropdown.defaultProps = {
  containerClass: '',
  buttonText: "Click",
  buttonClass: "",
  menuClass: ""
};
