// Packages
import dayjs from "dayjs";
import jsPDF from "jspdf";

// Utils
import { ignoreTimeZone } from "./helpers";

const FSR_PDF_STYLING = `ul {list-style-type: none; padding: 0; margin: 0;}
div, tr, td, li, ul {page-break-inside: avoid; page-break-after: avoid; page-break-before: avoid; font-size: 0.9rem; }
li{ padding: 2px 0; }
.services-data td{vertical-align: middle;}
.hotel-data td{vertical-align: middle;}
#fle3x-export-button{display: none;}
.w3-text-grey{color: #878484;}.w3-text-blue{color: #3da0d9;}.w3-text-red{color: #db1d0f;}.w3-text-green{color: #127309;}.w3-text-pink{color: #d90f8f;}.request-block{padding:20px 5px; font-family: arial;}.custom-table{width:100%;margin:0 0 15px;border-collapse:collapse;border-spacing:0}.custom-table td{border:2px solid #d6d5d5;padding:5px 15px;text-align:center}.custom-table .title{background:#f4f2f2;color:#000;font-weight:700}.custom-table .code{display:block}.custom-table .tag-text{background-color:#f4f2f2;color:#5d54a4;padding:0 4px}.table-heading{display:block;border:2px solid #d6d5d5;background-color:#f4f2f2;color:#5d54a4;text-transform:uppercase;padding:10px;text-align:center;font-weight:700;letter-spacing:1px;margin:0 0 15px}.catering-detail{text-align:left;display:flex}.catering-detail strong{font-weight:700;background:#f4f2f2;padding:4px 5px}.catering-detail .text{flex-grow:1;flex-basis:0;min-width:0;border:1px solid #d6d5d5;border-left:2px solid #d6d5d5;padding:4px 5px}.catering-detail:first-child .text{border-top:0}
`;

const INVOICE_REPORT_PDF_STYLING = `
@font-face {
    font-family: 'Hurra';
    src: url('/assets/fonts/Hurra.ttf') format('truetype'),
}
tr, td, li, ul {page-break-inside: avoid; page-break-after: avoid; page-break-before: avoid;}
body{ font-family: helvatica; padding: 10px 15px; font-size: 19px; }
table{ width: 100%; border-spacing: 0; }
table td, table th{ border: 1px solid grey; padding: 2px; }
.spa-logo{ margin: 0; }
.performa-invoice{ font-weight: bold; width: 100%; text-align: center; margin: auto; opacity: 0.7; font-size: 22px; }
.gap{ min-height: 150px; }
.w3-row{ display:flex; width: 100%; }
.w3-row .w3-half{ width: 50%; }
.w3-text-grey{opacity: 0.7;}
.p-2{ padding: 1rem; }
.text-center{ text-align: center; }
.aircraft-wise-section{ padding: 5px; 0; }
.aircraft-wise-section .total-amount{ text-align: right; }
.ar .aircraft-wise-section .total-amount{ text-align: left; }
.w3-large{ font-size: 21px !important; }
.w3-bar { width: 100%; padding-top: 4px; padding-bottom: 4px; }
.invoice-report-page-2-footer{ text-align: center; }
.aircraft-section-header{ margin: 22px 0; display: flex; justify-content: space-between; align-items: center; }
#invoice-pdf-aircraft-wise-breakdown{ padding: 12px 0; }
#letter-head-content-input{ display: none; }
#letter-head-content-preview{ margin: 15px 0; }
.invoice-report-hidden-section{ display: none; }
.taxes-summary-section table { margin-top: 10px; }
textarea{ width: 100%; height: auto; border: 0px; }
.ar .signature-section{ text-align: left; margin: auto; }
.ar{ direction: rtl; }
.ar, .ar textarea, .ar table, .ar td, .ar th .ar p{ font-family:  sans-serif !important; }
`;
const SPA_LOGO = `
    <svg width=150 version=1.1 viewBox="0 0 2048 639" xmlns=http://www.w3.org/2000/svg>
    <path transform=translate(0) d="m0 0h2048v639h-2048z" fill=#FCFCFC />
    <path transform=translate(0,164) d="m0 0h11l19 3 48 10 33 6 48 10 36 7 39 8 36 7 39 8 36 7 69 14 1 6v59l1 67-10-3-15-8-11-5-14-6-4-3-7-3-2-2-12-2-16-8-12-5-11-2-5-3-21-7-10-3-20-3-7-2-18-2-18-3h-28l-26 5-13 2h-4l-8-10-7-8-23-23-18-22-9-10-17-17-7-8-9-11-7-9-6-5-7-8-10-11-4-5z" fill=#02032F />
    <path transform=translate(869,164) d="m0 0h10l-2 4-9 11-18 18-9 11-5 4-3 6-13 13-7 8-8 8-6 7-9 9-2 5-17 17-7 8-18 18h-16l-27-5-16-1-20 2-10 2-20 2-12 3-14 2-8 3-10 3-5 2-9 3-4 2-11 2-6 3-8 3-3 2-7 2-4 3-9 2-15 8-7 3-15 6-10 6-9 5-11 2v-13l3-36 1-70 1-12 9-3 5-2 23-3 18-4 32-7 29-5 38-8 12-3 22-3 30-7 16-3 7-2 21-3 18-4 29-6 7-2 24-4 52-11z" fill=#02032F />
    <path transform=translate(437) d="m0 0h7l2 12 4 5 1 7h5l5-4 12-3 2-1h13l3 3-6 5-8 6-1 4 45 1 5 3 13 2 21 12 11 8 10 8 9 11 2 6-5-1-26-13-10-2-9-3-23-4-23-1v2l11 2 3 3 12 2 6 4 7 3 7 6 7 3 18 18 12 19 4 10 6 14 3 14 1 4-7-6-30-30-8-7-8-6-11-7-12-8-3-1 6 12 9 17 5 13 3 13 3 14-1 33h-3l-4-16-8-16-9-17-10-14-9-10-8-7v-2l-6-3 3 8 1 15 3 17v11l-3 21-2 20-1 3h-3l-4-4-4-21-3-6-3-10-7-20-3-10-5-11-6-7-2-8h-6l-6 9-11 25-4 9-3 13-11 33-4 7-3-1-2-21-2-18v-15l2-13 1-18 2-5 2-4-2-1-2 4-10 9-8 8-3 4h-2l-2 5-7 11-15 30-3 12-3 2-1-1-1-11v-25l2-11 6-15 6-13 3-9 7-8 2-6-5 1-15 10-9 5-5 4-39 39-4 2 2-16 6-16 10-19 9-10 15-15 18-11 12-6 8-1 3-3 11-2-1-1h-24l-6 2-19 3-10 3-11 4-19 10-4 1 1-7 7-8 6-5 5-6 8-5 15-9 8-5 15-2 3-3 32-1 16-1-5-5-12-11v-1h17l15 5 2 2 7 1 2-12 6-9z" fill=#02032F />
    <path transform=translate(1011,164) d="m0 0h116l21 1v44l-4 1-129 1-1 43h134l1 9v80l-12 11-32 32-4 2h-132v-45l134-1v-41l-1-1-133-1v-93z" fill=#02032F />
    <path transform=translate(1724,118) d="m0 0h44l1 19v241l-1 10h-44l-1-44v-3-86h-89v88h86l-4 7-5 7-11 8-10 10-7 8-6 5h-88l-1-1v-132l3-5 41-41h92z" fill=#02032F />
    <path transform=translate(734,313) d="m0 0h10l-2 5-12 13-7 8-22 22-6 9-4 4-5 4-3 5-13 13-7 8-7 7-6 8-5 4-4 5-3 5-12 12-7 8-18 18 2 4 8 7 16 7 6 4 10 5 16 6 9 4 9 2 3 2 6 1 19 1 13 4 1 5-17 2-5 4h-57l-12-4-9-2-18-8-10-5-10-6-2-2v-5l7-9 7-6 2-6-2-5-10-6-13-7-14-8-7-3-10-7-16-9-9-6-15-8-14-9-5-3-6-3v-2l15-8 16-8 10-6 32-16 23-11 14-7 8-2 21-9 11-4 35-10 14-3 2-1 19-1z" fill=#02032F />
    <path transform=translate(143,313) d="m0 0 44 1 14 1 27 6 29 9 18 7 9 4 9 3 7 3 15 7 7 4 6 2 10 5 4 3 6 2 4 3 7 3 9 4 8 5 23 12 6 4-3 3-16 8-10 7-8 4-14 9-19 10-8 6-8 4-9 5-12 7-9 4-7 5h-2l6 9 12 12 3 5-2 4-10 5-6 5-17 7-10 4-12 3-7 2h-49l-9-1-6-3-13-2-4-2v-2l13-4 20-2 9-1 7-3 10-3 31-13 24-13 5-5 1-3-5-8-5-4-7-8-17-17-7-8-18-22-22-22-7-8-5-6-7-9-5-4-6-8-15-15-7-8-8-9 1-2z" fill=#02032F />
    <path transform=translate(1216,209) d="m0 0h138v179h-45l-1-44v-3-85h-86v86l83 1-2 6-7 8-8 7-7 8-6 4-4 6-4 5-3 1h-88v-133l4-7 7-8 6-4 6-7 4-4 8-6 3-5z" fill=#02032F />
    <path transform=translate(1909,209) d="m0 0h139v179h-45l-1-47 1-86h-89v88h86l-5 8-36 36-3 1h-87v-134l12-15 8-5 6-9 8-5 3-3z" fill=#02032F />
    <path transform=translate(1384,209) d="m0 0h27l17 1 1 133 88-1 1-133h45v134l-42 42-5 3h-132z" fill=#02032F />
    <path transform=translate(969,449) d="m0 0h74l21 6 12 5 9 7 6 7 7 18 1 6v23l-4 10-2 7-20 20-13 3-3 2-8 1h-54v60l-1 11h-25z" fill=#02032F />
    <path transform=translate(1459,509) d="m0 0h21l5 3 11 2 8 9 7 11 1 1 1-20 1-1 23-1 1 1v121h-24l-1-9v-13l-4 4-6 10-8 7-10 2-2 3h-29l-4-3-9-2-7-7-6-5-4-5-4-13-2-6-2-16v-16l3-20 3-7 3-9 10-11 6-5 13-3z" fill=#02032F />
    <path transform=translate(418,428) d="m0 0h1l1 7 1 48 3 33 1 19 1 68 2 26v6l-7-3-10-13-9-10-24-24-10-13-9-10-9-9-7-8-17-17-6-7-4-7-8-7-4-7 8-7 13-9 10-5 14-10 9-5 11-7 9-6 18-11 7-5 12-5z" fill=#02032F />
    <path transform=translate(460,428) d="m0 0 16 8 8 5 9 6 15 8 8 6 11 7 6 3 11 8 20 12 11 9-1 3-14 14-10 13-7 8-7 7-7 8-14 14-5 6-4 3-4 8-10 9-7 8-9 9-7 8-8 7-4 8-4 1 1-94 3-39 1-14 1-56z" fill=#02032F />
    <path transform=translate(1714,509) d="m0 0h19l17 5 11 7 10 9 7 18 5 19v11l-4 1-90 1 1 11 6 14 6 7 8 5 5 1h12l9-1 10-6 5-6 7-11 2-1h23l-2 6-5 12-6 9-13 13-14 3-3 3h-34l-4-3-13-4-12-11-6-9-7-15-1-6v-34l2-7 5-12 6-10 9-9 10-5z" fill=#02032F />
    <path transform=translate(1798,209) d="m0 0h44l1 1v177l-1 1h-44z" fill=#030430 />
    <path transform=translate(1282,513) d="m0 0 25 1 9 33 2 5 3 16 10 34 3 10 3 12 3 9h2l1-13 3-7 4-20 6-19 5-16 3-16 3-8 2-9 5-11 5-1h17l3 1-2 14-9 30-8 25-5 19-5 15-3 10-5 8h-45l-3-1-4-15-5-15-5-18-12-38-4-16-5-15v-3z" fill=#02032F />
    <path transform=translate(1583,469) d="m0 0h25v21l-4 7-5 6-11 9 3 1h52l5 1v15l-7 1h-30l-2 1v72l2 6 7 7 7 2h12l7-2 4-2 1 9-1 10-10 4-3 2h-28l-4-3-9-3-6-7-3-7-2-3v-86l-19-1-1-2v-13l2-1h18z" fill=#02032F />
    <path transform=translate(1471,529) d="m0 0h15l7 3 11 9 9 10v46l-12 13-7 6-7 3h-15l-9-3-8-6-8-10-3-6-1-5v-28l2-9 7-10 8-8 7-4z" fill=#FDFDFD />
    <path transform=translate(995,473) d="m0 0h49l10 2 9 6 5 8 5 12v14l-3 6-2 6-7 8-6 5-6 3-8 1h-45l-2-7v-37z" fill=#FDFDFD />
    <path transform=translate(1125,513) d="m0 0h23l1 1 1 9v24l1 6 2 1 1 6-2 7-1 1-1 20-1 47h-25v-121z" fill=#02032F />
    <path transform=translate(1240,513) d="m0 0h12l2 3v33l-1 86h-24v-121z" fill=#02032F />
    <path transform=translate(1713,529) d="m0 0h19l7 3 12 12 7 18v6h-69l-1-1v-7l6-15 10-11 6-4z" fill=#FDFDFD />
    <path transform=translate(1798,118) d="m0 0h44l1 1v45l-6 1h-38l-1-1z" fill=#030430 />
    <path transform=translate(1183,509) d="m0 0h17l8 5 1 7-5 9-2 6-15-3h-7l-9 2-13 11-5 5v-17l3-5 12-13 12-6z" fill=#02032F />
    <path transform=translate(1229,458) d="m0 0h24v26h-24l-1-1v-21z" fill=#030430 />
    <path transform=translate(1011,164) d="m0 0h116l21 1-1 5-3-1-39-1h-91l-7 4-11 11-8 7-16 16-2 1 2-4z" fill=#000120 />
    <path transform=translate(1909,209) d="m0 0h139v3l-11 1h-125l-3 2z" fill=#000120 />
    <path transform=translate(1798,118) d="m0 0h44l1 1v45h-22v-1l17-2 1-1v-28l-2-9-13-1h-13l-7 1-1 1-1 17 1 20 3 3h-8z" fill=#000121 />
    <path transform=translate(970,450) d="m0 0h78l2 1-1 2h-77l-2-1z" fill=#00001B />
    <path transform=translate(1384,209) d="m0 0h27l16 1v5l-3-1-12-1h-22l-4 2v-3h-2z" fill=#00001F />
    <path transform=translate(1304,210) d="m0 0h49l-1 3-55-1v-1z" fill=#00001B />
    <path transform=translate(1519,210) d="m0 0h43v2l-28 1-11 1-2 1z" fill=#00001B />
    <path transform=translate(1010,165) d="m0 0 2 1-12 13-8 7-20 20-2 1 2-4z" fill=#010123 />
    <path transform=translate(993,608) d="m0 0h1v27h-16l-8-1v-5l3 3h18l1-5z" fill=#00001B />
    <path transform=translate(1810,210) d="m0 0h32l-1 3-28-1z" fill=#00001B />
    <path transform=translate(258,214) d="m0 0h7l14 3 1 2-11-1-11-3z" fill=#010122 />
    <path transform=translate(1146,342) d="m0 0h2l-1 3-8 7-3 1 2-4z" fill=#00001E />
    <path transform=translate(1125,625) d="m0 0h1l1 7 6 2v1h-9v-9z" fill=#00001B />
    <path transform=translate(1085,469) d="m0 0 4 2 4 7-1 4-7-11z" fill=#010122 />
    <path transform=translate(1800,369) d="m0 0h1l1 10-1 5-2-1v-11z" fill=#00001B />
    <path transform=translate(1692,370) d="m0 0v3l-8 9h-2l2-4z" fill=#00001F />
    </svg>       
`;

const BILLS_PDF_STYLING = `
table{ width: 100%; font-family: sans-serif !important; }
svg, react-table-lite-actions{ display: none; }
.w3-tooltip{ display:none !important; }
table tr td:nth-child(8), 
table tr th:nth-child(8) {
    display: none;
}
td{
    text-align: center;
}
input, select{ border: 0px; background: white; text-align: center; width: fit-content; font-family: sans-serif !important; }
`;

const setFsrPdfContent = (fsrDivContent, windowName = "FSR") => {
    const pdfContent = `
    <html>
    <head>
    <title>${windowName}</title>
    <style> 
        ${FSR_PDF_STYLING}
    </style>
    </head> 
    <body>   
    <div class="request-block"> ${fsrDivContent} </div> 
    <div style="position: absolute; top: 40px; left: 25px;"> 
        ${SPA_LOGO}
    </div>
    </body> 
    </html>`;
    return pdfContent;
};

const setCateringPdfContent = (cateringDivContent, windowName = "Catering") => {
    const pdfContent = `
    <html>
    <head>
    <title>${windowName}</title>
    <style> 
        ${FSR_PDF_STYLING}
    </style>
    </head> 
    <body>
    <div class='table-heading' style='height: 50px; display: flex; align-items: center; justify-content: center;'> 
        ${windowName} 
    </div>
    <div style="position: absolute; top: 20px; left: 25px;"> 
        ${SPA_LOGO}
    </div>
    <div class="request-block">
        <div class="catering-data"> ${cateringDivContent} </div>
    </div>
    <p> Best Regards, </p>
    <p> <strong>P.O Box </strong> 620, Jeddah 21231, Saudi Arabia <strong>Tel</strong> 966 12 686 7849 / 966 12 686 7850 </p>     
    </body>
    </html>`;
    return pdfContent;
};

const setServicesPdfContent = (servicesDivContent, windowName = "Services") => {
    const pdfContent = `
    <html>
    <head>
    <title>${windowName}</title>
    <style> 
        ${FSR_PDF_STYLING}
    </style>
    </head> 
    <body>
    <div class='table-heading' style='height: 50px; display: flex; align-items: center; justify-content: center;'> 
        ${windowName} 
    </div>
    <div style="position: absolute; top: 20px; left: 25px;"> 
        ${SPA_LOGO}
    </div>
    <div class="request-block">
        <div class="catering-data"> ${servicesDivContent} </div>
    </div>
    <p> Best Regards, </p>
    <p> <strong>P.O Box </strong> 620, Jeddah 21231, Saudi Arabia <strong>Tel</strong> 966 12 686 7849 / 966 12 686 7850 </p>     
    </body>
    </html>`;
    return pdfContent;
};

const setInvoiceReportPdfContent = (invoiceDivContent, windowName = "Invoice") => {
    let pdfContent = `
    <html>
    <head>
    <title>${windowName}</title>
    <style> ${INVOICE_REPORT_PDF_STYLING} </style>
    <body>
    ${invoiceDivContent}
    </body>
    </html>`;
    pdfContent = pdfContent.replaceAll('<div class="spa-logo"> </div>', `<div class="spa-logo"> ${SPA_LOGO}</div>`);
    pdfContent = pdfContent.replaceAll('<div class="performa-invoice"></div>', '<div class="performa-invoice"> Performa Invoice </div>')
    return pdfContent;
};

const setBillsListPdfContent = (bilsDivContent, windowName = "Bills") => {
    const pdfContent = `
    <html>
    <head>
    <title>${windowName}</title>
    <style> 
        ${BILLS_PDF_STYLING}
    </style>
    </head> 
    <body>   
    <div class="request-block"> ${bilsDivContent} </div> 
    <div style="position: absolute; top: 40px; left: 25px;"> 
        ${SPA_LOGO}
    </div>
    </body> 
    </html>`;
    return pdfContent;
};

const fsrJSPDF = (fsrDivContent, windowName = "FSR") => {
    const pdfReportWrapper = document.createElement('div');
    pdfReportWrapper.style.width = '1300px';
    pdfReportWrapper.style.margin = '0px';
    pdfReportWrapper.style.pageBreakInside = 'avoid';
    pdfReportWrapper.style.pageBreakBefore = 'avoid';
    pdfReportWrapper.style.pageBreakAfter = 'avoid';
    pdfReportWrapper.innerHTML += fsrDivContent;
    let doc = new jsPDF('p', 'px', [650, 1500]);
    doc.html(pdfReportWrapper, {
        y: 0,
        x: 18,
        margin: [25, 0, 25, 0],
        autoPaging: 'text',
        html2canvas: {
            scale: 0.5,
            logging: false,
        },
        callback: function (doc) {
            doc.save(windowName);
        },
    });
};

// const LegacyPDFdownloader = () => {
//     // create a wrapper div for content and set it's width
//     const wrapper = document.createElement('div');
//     wrapper.style.width = '1020px';
//     wrapper.style.margin = '0px';
//     let selector = "";
//     if (pdfOption === "full") selector = ".request-block";
//     else if (pdfOption === "catering") selector = ".catering-data";
//     else if (pdfOption === "hotel") selector = ".hotel-data";
//     else if (pdfOption === "transportation") selector = ".transportation-data";
//     else if (pdfOption === "services") selector = ".services-data";
//     const fileName = `FSR_${requestId}${selector !== ".request-block" ? '_' + selector?.replace(/^[.]|[-][d][a][t][a]/g, '') : ''}.pdf`;

//     const Footer = document.createElement("div");
//     Footer.innerHTML = `       
//     <p> Best Regards, </p>
//     <p> <strong>P.O Box </strong> 620, Jeddah 21231, Saudi Arabia <strong>Tel</strong> 966 12 686 7849 / 966 12 686 7850 </p>      
//     `;

//     // create a duplicate of the content div which is to be printed
//     let sourceDiv = document.querySelector(selector);
//     let requestData = document.querySelector('.request-basic-data');
//     let fsrInfoData = document.querySelector('.fsr-info-data');
//     let doc = new jsPDF('p', 'px', [650, 1500]);
//     sourceDiv = sourceDiv.cloneNode(true);
//     requestData = requestData.cloneNode(true);
//     fsrInfoData = fsrInfoData.cloneNode(true);
//     sourceDiv.style.padding = '0px';
//     // If report of only catering or hotel is being downloaded then add the request ID to the report
//     if (pdfOption !== "full") {
//       wrapper.innerHTML = `
//       <div style="width: 100%; text-align: center;">
//         <img src="/assets/images/spa_logo_report.png" alt="Saudia Private"> </img>
//         <p style="font-size: 1.3em; font-weight: bold;"> Aircraft <span class='capitalize'>${selector?.replace('.', '')?.split('-')?.[0]}</span> Request </p>
//       </div>
//       <div style="display: flex; width: 100%; justify-content: space-between;">
//       <div> 
//         <strong>Date</strong>: ${dayjs()?.format('ddd, DD-MM-YYYY')}
//         <br>
//         <strong>Time</strong>: ${dayjs(ignoreTimeZone(new Date().toISOString()))?.format('HH:mm:ss')}
//         <br>
//         <img style="width: 400px; margin-bottom: 5px;" src="/assets/images/services-report-arabic.png"> </img>
//       </div>
//       <div>
//       <strong>P.O Box</strong> 620, Jeddah 21231, Saudi Arabia
//       <br>
//       <strong>Tel</strong> 966 12 686 7849 / 966 12 686 7850
//       </div>
//       </div>
//       `;
//       wrapper.appendChild(fsrInfoData);
//     }
//     // Else if full report is downloaded, then add watermark for FSR status
//     else {
//       // create water mark
//       for (let i = 1; i <= 2; i++) {
//         doc.setPage(i)
//         doc.saveGraphicsState();
//         doc.setGState(new doc.GState({ opacity: 0.2, strokeOpacity: 1 }));
//         doc.setTextColor('#1D1D1D40');
//         doc.text(fsrDetails?.status?.toUpperCase() || '', 40, 15, { align: 'center', baseline: 'middle' })
//         doc.restoreGraphicsState();
//       }
//     }

//     wrapper.appendChild(sourceDiv);

//     if (pdfOption !== "full") {
//       wrapper.appendChild(Footer);
//     }

//     doc.html(wrapper, {
//       callback: function (doc) {
//         doc.save(fileName);
//       },
//       autoPaging: 'text',
//       html2canvas: { scale: 0.6 },
//       margin: [25, 0, 25, 0],
//       x: 18,
//       y: 0
//     });
// }

export { setFsrPdfContent, setCateringPdfContent, setServicesPdfContent, setInvoiceReportPdfContent, setBillsListPdfContent, fsrJSPDF };