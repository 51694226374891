// Packages
import React from 'react';
import dayjs from 'dayjs';

// Utils
import { Modal } from '../../../../components';
import { ignoreTimeZone } from '../../../../utils';

function InvoiceVersionListModal(props) {
    return (
        <Modal isOpen={props?.showInvoiceVersionListModal} modalClass="w-fit w3-round-medium">
            <div className="w-full">
                <div className='bg-primary-blue w3-text-white py-1 px-2 flex flex-wrap items-center'>
                    <h3>
                        Invoice Versions
                    </h3>
                </div>
                <div className='py-1 px-2'>
                    <React.Fragment>
                        {props?.invoiceReportVersions &&
                            props?.invoiceReportVersions?.length
                            ? <table className='w3-table w3-medium w3-bordered'>
                                <thead>
                                    <tr>
                                        <th> Version </th>
                                        <th> Created Date </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props?.invoiceReportVersions?.slice()?.reverse()?.map((item) => (
                                        <tr key={item?.id}>
                                            <td>{item?.version}</td>
                                            <td>{dayjs(ignoreTimeZone(item?.createdat))?.format('YYYY-MM-DD HH:mm')}</td>
                                            <td>
                                                <div onClick={e => props?.handleLoadInvoiceReportVersion(item?.id)} className='w3-small px-2 p-1 bg-primary-blue w3-text-white cursor-pointer'> Load </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            : <div> No invoice report versions found </div>
                        }

                    </React.Fragment>
                    <div className="h-3"></div>
                    <div className="flex justify-end">
                        <button onClick={props?.handleCloseInvoiceVersionListModal} className='w3-btn w3-grey w3-text-white'> Close </button>
                    </div>
                    <div className="h-3"></div>
                </div>
            </div>
        </Modal>
    );
}

export default InvoiceVersionListModal;
