// Packages
import React, { useState } from 'react';

// Utils
import { Modal } from '../../../../components';

function InvoiceServiceTrackModal(props) {
    return (
        <Modal isOpen={props?.showServiceTrackModal} modalClass="w-fit w3-round-medium">
            <div className="w-full">
                <div className='bg-primary-blue w3-text-white py-1 px-2 flex flex-wrap items-center'>
                    <h3>Invoice Service</h3>
                </div>
                <div className='p-2'>
                    <table className="w3-table w-full w3-medium border-collapse w3-bordered">
                        <thead className='w3-grey'>
                            <tr className="bg-gray-200">
                                <th className="border border-gray-300 px-4 py-2">Costplus Id</th>
                                <th className="border border-gray-300 px-4 py-2">Leg</th>
                                <th className="border border-gray-300 px-4 py-2">Service</th>
                                <th className="border border-gray-300 px-4 py-2">Quantity</th>
                                <th className="border border-gray-300 px-4 py-2">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props?.serviceTrackLog ? props?.serviceTrackLog?.map((track, index) => (
                                <tr key={index}>
                                    <td>
                                        <a className='w3-text-blue' href={`/costplus-invoices/view/${track?.cost_plus_item?.cost_plus_invoice}`} target='_blank'>
                                            {track?.cost_plus_item?.cost_plus_invoice}
                                        </a>
                                    </td>
                                    <td>
                                        {track?.cost_plus_item?.arriving_from_airport_iata
                                            ? `
                                            ${track?.cost_plus_item?.arriving_from_airport_icao} / 
                                             ${track?.cost_plus_item?.arriving_from_airport_iata} - 
                                            ${track?.cost_plus_item?.arrival_to_airport_icao} / 
                                            ${track?.cost_plus_item?.arrival_to_airport_iata}
`
                                            : '-'}
                                    </td>
                                    <td>
                                        {track?.cost_plus_item?.service_name}
                                    </td>
                                    <td>
                                        {track?.cost_plus_item?.quantity}
                                    </td>
                                    <td>
                                        {Number(track?.cost_plus_item?.total_cost)?.toFixed(3)}
                                    </td>
                                </tr>
                            ))
                                : <tr></tr>}
                            {/* Sample data, replace with dynamic data */}
                        </tbody>
                    </table>
                    <div className="h-3"></div>
                    <div className="flex justify-end">
                        <button onClick={props?.handleCloseServiceTrackModal} className='w3-btn w3-grey w3-text-white'> Close </button>
                    </div>
                    <div className="h-3"></div>
                </div>
            </div>
        </Modal>
    );
}

export default InvoiceServiceTrackModal;
