import { constants } from "../utils";

const UpdateStandaloneHotelBillsBulkRequest = (token, data) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    return (fetch(`${constants.BASEURL}/api/costplusinvoice/update_hotel_bulk_invoice/`, {
        method: 'POST',
        body: data,
        headers: requestHeaders,
        redirect: 'follow'
    }));
};

export default UpdateStandaloneHotelBillsBulkRequest;
