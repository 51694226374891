
import { validateSubmissionData } from './validator';
import { fsrJSPDF, setInvoiceReportPdfContent, setFsrPdfContent, setCateringPdfContent, setServicesPdfContent, setBillsListPdfContent } from './pdf-report-formatings';
import { findObjectDifferences, ignoreTimeZone, getCurrentGMTDateTime, isLocalFlight, isInternationalFlight, encodeString, decodeString, decodeToken, generateUID, authenticationErrorHandle, calculateAirportFlightTime, minutesToHours, verifyFileSizeLessThanOneMb, formatPrice } from './helpers';
import { BASEURL, AIRPORTSEARCHURL, AIRPORTDISTANCEURL, ENCODER, ENCODER_LOGIN_TIME, SIDEBARFULL, SIDEBARMINI, RESPONSECODES, ERRORTOAST, SUCCESSTOAST, NOTIFYTOAST, COUNTRYARRAY, HOTELSERVICETYPE, CATERINGSERVICETYPE, GROUNDSERVICETYPE, FLEETSERVICETYPE, OPSSERVICETYPE, TRANSPORTSERVICETYPE, CARRENTALSERVICETYPE, SESSIONTIMEOUT, LOGOUTERRORTYPES, XRAPIDAPIKEY, XRAPIDAPIHOST, PAGINATIONPERPAGE, CAPTAINTITLE, CONTRACT_51_FLEET_IDS, TIME_FORMAT_REGEX, DATE_FORMAT_REGEX, VALID_TIME_REGEX, CREW_GENDER, MONTHS, AVAILABLE_FSR_FILTERS } from './constants';

const constants = {
    BASEURL,
    AIRPORTSEARCHURL,
    AIRPORTDISTANCEURL,
    ENCODER,
    ENCODER_LOGIN_TIME,
    SIDEBARMINI,
    SIDEBARFULL,
    RESPONSECODES,
    SUCCESSTOAST,
    NOTIFYTOAST,
    ERRORTOAST,
    COUNTRYARRAY,
    HOTELSERVICETYPE,
    CATERINGSERVICETYPE,
    GROUNDSERVICETYPE,
    FLEETSERVICETYPE,
    OPSSERVICETYPE,
    TRANSPORTSERVICETYPE,
    CARRENTALSERVICETYPE,
    SESSIONTIMEOUT,
    LOGOUTERRORTYPES,
    XRAPIDAPIHOST,
    XRAPIDAPIKEY,
    PAGINATIONPERPAGE,
    CAPTAINTITLE,
    CONTRACT_51_FLEET_IDS,
    TIME_FORMAT_REGEX,
    DATE_FORMAT_REGEX,
    VALID_TIME_REGEX,
    CREW_GENDER,
    MONTHS,
    AVAILABLE_FSR_FILTERS
}

export {
    formatPrice,
    validateSubmissionData,
    findObjectDifferences,
    ignoreTimeZone,
    getCurrentGMTDateTime,
    isLocalFlight,
    isInternationalFlight,
    encodeString,
    decodeString,
    decodeToken,
    generateUID,
    authenticationErrorHandle,
    calculateAirportFlightTime,
    minutesToHours,
    verifyFileSizeLessThanOneMb,
    fsrJSPDF,
    setFsrPdfContent,
    setCateringPdfContent,
    setServicesPdfContent,
    setInvoiceReportPdfContent,
    setBillsListPdfContent,
    constants
};