import { constants } from "../utils";

const FetchStandAloneHotelBillsBulkWebhookRequest = (token, ContractId, data) => {
  var requestHeaders = new Headers();  
  requestHeaders.append("Authorization", `Bearer ${token}`);
  requestHeaders.append("Content-Type", "application/json");
  return fetch(`${constants.BASEURL}/api/costplusinvoice/get_hotel_bils_from_webhook/${ContractId}/`, {
    method: "POST",
    body: data,
    headers: requestHeaders,
    redirect: "follow",
  });
};

export default FetchStandAloneHotelBillsBulkWebhookRequest;
