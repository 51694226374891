// Packages
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';

// APIs
import { AirbaseListRequest, FsrDetailsRequest, ServiceListRequest, AircraftDetailsRequest, CrewMemberByFleetListRequest, GetFsrPdfRequest, ExportFsrToFleexxRequest } from '../../../requests';

// Utils
import { constants, decodeString, authenticationErrorHandle, ignoreTimeZone, setFsrPdfContent, setCateringPdfContent, setServicesPdfContent } from '../../../utils';
import { auth, airbasesCache, servicesCache } from '../../../atoms';
import { Save, Modal } from '../../../components';

function RequestView(props) {

  const [pdfOption, _pdfOption] = useState('full_fsr');
  const [isLoading, _isLoading] = useState(false);
  const [isSubmitting, _isSubmitting] = useState(false);
  const [fsrDetails, _fsrDetails] = useState(null);
  const [aircraft, _aircraft] = useState(null);
  const [showPdfOption, _showPdfOption] = useState(false);
  const [authState, _authState] = useAtom(auth);
  const [services, _services] = useAtom(servicesCache);
  // const [crewMembers, _crewMembers] = useAtom(crewMembersCache);
  const [crewMembers, _crewMembers] = useState([]);
  const [airbases, _airbases] = useAtom(airbasesCache);
  const { requestId } = useParams();

  // Get fsrDetails & get services, crew members & airbases either from cache or from server
  useEffect(() => {
    if (authState && !fsrDetails && !isLoading && !props?.fsrData) {
      getFsrDetails();
    }
    if (authState) {
      if (!services || !services.created || Date.now() - services.created >= 1200000) {
        getServices();
      }
      // if (!crewMembers || !crewMembers.created || Date.now() - crewMembers.created >= 1200000) {
      //   getCrewMembers();
      // }
      if (!airbases || !airbases.created || Date.now() - airbases.created >= 1200000) {
        getAirbases();
      }
    }
  }, [authState]);

  // instead of using getFsrDetails() use the data from props for the case of step 3 of FSR form
  useEffect(() => {
    if (props && props?.fsrData) {
      _fsrDetails(props?.fsrData);
    }
  }, [props?.fsrData]);

  // Get aircraft associated with FSR request
  useEffect(() => {
    if (fsrDetails && fsrDetails?.aircraft)
      getAircraftDetails();
  }, [fsrDetails?.aircraft]);

  // Fetch list crew members associated with the fleet when fleet is changed
  useEffect(() => {
    if (fsrDetails?.fleet) {
      getCrewMembersByFleet();
    }
  }, [fsrDetails?.fleet]);

  const getFsrDetails = () => {
    const token = decodeString(authState);
    _isLoading(true);
    FsrDetailsRequest(token, requestId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        _fsrDetails(data);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch request details' });
        }
      )
  };

  const getAircraftDetails = () => {
    const token = decodeString(authState);
    const aircraftId = fsrDetails?.aircraft;
    _isLoading(true);
    AircraftDetailsRequest(token, aircraftId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        _aircraft(data);
      } else {
        throw 'Request Failed';
      }
    })
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch aircraft details' });
        }
      )
  };

  const getServices = () => {
    const token = decodeString(authState);
    ServiceListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _services({
          data: [...data.results],
          created: Date.now()
        });
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch services' });
        }
      )
  };

  const getCrewMembersByFleet = () => {
    const token = decodeString(authState);
    CrewMemberByFleetListRequest(token, fsrDetails?.fleet).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _crewMembers({
          data: [...nonPaginatedData]
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch crew members" });
    });
  };

  const getAirbases = () => {
    const token = decodeString(authState);
    AirbaseListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(nonPaginatedData => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in cache with current time
        _airbases({
          data: [...nonPaginatedData],
          created: Date.now()
        });
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch airbases' });
        }
      )
  };

  // *********** Handlers ***********

  const handleShowPDFOptions = () => {
    _showPdfOption(true);
  };

  const handleClosePDFOptions = () => {
    _showPdfOption(false);
  };

  const handleChangePdfOption = (e) => {
    _pdfOption(e.target.value);
  };

  const handleDirectRenderPdf = (type) => {
    let PDFContent = "";
    if (type === 'full_fsr') {
      const FSRContent = document.querySelector(".request-block")?.innerHTML || '';
      PDFContent = setFsrPdfContent(FSRContent, `FSR-${requestId}`);
    } else if (type === 'catering') {
      const CateringContent = document.querySelector(".catering-data")?.innerHTML || '';
      PDFContent = setCateringPdfContent(CateringContent, `FSR-${requestId}-Catering`);
    } else if (type === 'services') {
      const ServicesContent = `
        ${document.querySelector(".fsr-info-data")?.innerHTML || ''} 
        ${document.querySelector(".services-data")?.innerHTML || ''}
      `;
      PDFContent = setServicesPdfContent(ServicesContent, `FSR-${requestId}-Services`);
    }
    const printWindow = window.open('', '', 'height=1024,width=900,toolbar=no,menubar=no');
    printWindow.document.write(PDFContent);
    printWindow.document.close();
    printWindow.print();
  };

  const handleDownloadPdf = () => {
    handleClosePDFOptions();
    // Only Hotel and Catering PDFs are downloaded from server
    if (pdfOption?.includes('hotel') || pdfOption?.includes('catering')) {
      handleGetPdfFromServer();
    } else {
      handleDirectRenderPdf(pdfOption);
    }
  };

  const handleGetPdfFromServer = () => {
    const token = decodeString(authState);
    const data = JSON.stringify({ request_type: pdfOption?.replace(/[0-9-]*/g, '')?.toUpperCase(), leg_index: Number(pdfOption?.replace(/^[-a-zA-Z]*/g, '')) });
    GetFsrPdfRequest(token, data, requestId)
      .then(res => {
        if (!res.ok) {
          throw "Request Failed";
          // throw new Error('Network response was not ok');
        }
        return res.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `FSR-${requestId}-${pdfOption[0].toUpperCase() + pdfOption.slice(1)}.pdf`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(err => {
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch pdf file" });
      })
  };

  const handleExportFsrToFleexx = () => {
    if (isSubmitting) return;
    const token = decodeString(authState);
    _isSubmitting(true);
    ExportFsrToFleexxRequest(token, requestId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isSubmitting(false);
        if(data.success) {
          Store.addNotification({ ...constants.SUCCESSTOAST, message: 'FSR exported to fle3xx' });
          getFsrDetails();
        }
      } else {
        throw 'Request Failed';
      }
    }
    ).catch(
      err => {
        _isSubmitting(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to export FSR' });
      }
    )
  };

  // *********** Render Functions ***********

  const PDF_OPTIONS_MODAL = () => (
    <Modal isOpen={showPdfOption} modalClass="w-96 p-1 w3-round-medium">
      <div className="w-full p-2">
        <h3> Select PDF Type </h3>
        <div className="py-1">
          <div>
            <input onChange={handleChangePdfOption} checked={pdfOption === 'full_fsr'} type="radio" id="full_fsr" name="pdftype" value="full_fsr" />
            <label className="small-left-margin cursor-pointer" htmlFor="full_fsr">Full FSR</label>
          </div>
          <div>
            <input onChange={handleChangePdfOption} checked={pdfOption === 'catering'} type="radio" id="catering" name="pdftype" value="catering" />
            <label className="small-left-margin cursor-pointer" htmlFor="catering">Catering</label>
          </div>
          {/* <div>
            <input onChange={handleChangePdfOption} checked={pdfOption === 'hotel'} type="radio" id="hotel" name="pdftype" value="hotel" />
            <label className="small-left-margin cursor-pointer" htmlFor="hotel">Hotel</label>
          </div> */}
          {/* <div>
            <input onChange={handleChangePdfOption} checked={pdfOption === 'transportation'} type="radio" id="transportation" name="pdftype" value="transportation" />
            <label className="small-left-margin cursor-pointer" htmlFor="transportation">Transportation</label>
          </div> */}
          <div>
            <input onChange={handleChangePdfOption} checked={pdfOption === 'services'} type="radio" id="services" name="pdftype" value="services" />
            <label className="small-left-margin cursor-pointer" htmlFor="services">Services</label>
          </div>
        </div>

        {
          fsrDetails?.requestleg_requestLeg.map?.((L, index) => (
            L?.requestleghotel_requestLegHotel?.length
              ? <div key={index}>
                <input onChange={handleChangePdfOption} checked={pdfOption === `hotel-${index}`} type="radio" id={`hotel-${index}`} name="pdftype" value={`hotel-${index}`} />
                <label className="small-left-margin cursor-pointer" htmlFor={`hotel-${index}`}>{`Hotel (${L?.destination_airport_iata}/${L?.destination_airport_icao})`}</label>
              </div>
              : null
          ))
        }
        <div className="h-3"></div>
        <div className="flex justify-end">
          <button onClick={handleClosePDFOptions} className='w3-btn w3-grey w3-text-white'> Cancel </button>
          <button onClick={handleDownloadPdf} className='w3-btn bg-primary-blue w3-text-white small-left-margin'> Download </button>
        </div>
      </div>
    </Modal>
  );

  const REQUEST_DETAILS_SECTION = () => (
    <React.Fragment>
      <span className='table-heading request-basic-data flex-heading flex-wrap justify-between items-center w-full'>
        <div className=''>
          <div>
          <span>Request ID: {fsrDetails?.id}</span>
          <div>
          {
            fsrDetails?.status?.toLowerCase()?.includes('draft') && <div className='w3-text-grey text-left'> Draft </div>
          }
          {
            fsrDetails?.status?.toLowerCase()?.includes('confirm') && <div className='w3-text-blue text-left'> CONFIRMED </div>
          }
          {
            fsrDetails?.status?.toLowerCase()?.includes('cancel') && <div className='w3-text-red text-left'> CANCELLED </div>
          }
          {
            fsrDetails?.status?.toLowerCase()?.includes('complete') && <div className='w3-text-green text-left'> COMPLETED </div>
          }
          {
            fsrDetails?.status?.toLowerCase()?.includes('lock') && <div className='w3-text-pink text-left'> LOCKED </div>
          }
          </div>
          </div>
        </div>
        <div>  
          <div> Request Details </div>
          <div className='w3-small small-top-margin'>
          {
            <React.Fragment>
              {/* {fsrDetails?.status?.toLowerCase()?.includes('confirm') */}
              {!fsrDetails?.status?.toLowerCase()?.includes('draft')
                ? `Updated on: ${dayjs(ignoreTimeZone(fsrDetails?.updatedat)).format('DD-MM-YYYY HH:mm')}`
                : `Created on: ${dayjs(ignoreTimeZone(fsrDetails?.createdat)).format('DD-MM-YYYY HH:mm')}`
              }
            </React.Fragment>
          }
        </div>
        </div>  
        {fsrDetails?.status?.toLowerCase()?.includes('confirm') 
          ? <div
            id="fle3x-export-button"
            // onClick={handleExportFsrToFleexx}
            className='cursor-pointer capitalize underline no-opacity'
          >
            Export to fle3xx
          </div>
          : <div className='w-24'></div>
        }
      </span>
      <div className='fsr-info-data'>
        <table className="custom-table ">
          <tbody>
            <tr>
              <td className="title">FLT NBR</td>
              <td className="text">{fsrDetails?.flight_number}</td>
              <td className="title">ACFT TYPE</td>
              <td className="text">
                {aircraft && aircraft?.tailnumber}
              </td>
            </tr>
            <tr>
              <td className="title">ACFT REG</td>
              <td className="text">
                {aircraft && aircraft?.tailnumber}
              </td>
              <td className="title">ACFT BASE/F. WING</td>
              <td className="text">
                {airbases && airbases?.data?.find(ab => ab.id == fsrDetails?.airbase)?.name}
              </td>
            </tr>
            <tr>
              <td className="title"> Purpose of Flight </td>
              <td colSpan={3} className="text text-center">{fsrDetails?.purpose_of_flight}</td>
              {/* <td className="title"> Fleexx ID </td>
              <td className="text text-center">{fsrDetails?.fl3xx_id || '-'}</td> */}
            </tr>
          </tbody>
        </table>
      </div>
      <table className="custom-table">
        <tbody>
          {fsrDetails &&
            fsrDetails?.requestleg_requestLeg.map((L, index) => (
              <tr key={index} >
                {
                  L?.departure_date &&
                    L?.departure_time
                    ? <React.Fragment>
                      <td>{`${dayjs(L?.departure_date + "T" + L?.departure_time).format('DD-MM-YYYY HH:mm')}`}</td>
                      <td>{`${dayjs(L?.arrival_date + "T" + L?.arrival_time).format('DD-MM-YYYY HH:mm')}`}</td>
                    </React.Fragment>
                    : <React.Fragment>
                      <td>{`${dayjs(ignoreTimeZone(L?.departure_time)).format('DD-MM-YYYY HH:mm')}`}</td>
                      <td>{`${dayjs(ignoreTimeZone(L?.arrival_time)).format('DD-MM-YYYY HH:mm')}`}</td>
                    </React.Fragment>
                }
                <td>{`${L?.departure_airport_city} - ${L?.departure_airport_country} (${L?.departure_airport_icao} / ${L?.departure_airport_iata}) (${L?.departure_airport_type})`}</td>
                <td>{`${L?.destination_airport_city} - ${L?.destination_airport_country} (${L?.destination_airport_icao} / ${L?.destination_airport_iata}) (${L?.arrival_airport_type})`}</td>
                <td>{L?.leg_type || ''}</td>
                {/* <td>{L?.departure_airport_name || ''}</td>
                <td>{L?.destination_airport_name || ''}</td> */}
                {/* <td>No</td>
                <td>-</td> */}
              </tr>
            ))
          }
        </tbody>
      </table>
    </React.Fragment>
  );

  const SERVICES_REQUIRED_SECTION = () => (
    <React.Fragment>
      <div className="table-heading">SERVICE REQUIRED AS BELOW</div>
      <div className='services-data'>
        <table className="custom-table ">
          <tbody>
            <tr>
              <td className="title" colSpan="2">Route</td>
              <td className="title" colSpan="6"></td>
            </tr>
            <tr>
              <td className="title">From</td>
              <td className="title">To</td>
              <td className="title">Estimated Fuel Uplift (Pounds)</td>
              <td className="title" colSpan="2">Service Required</td>
              <td className="title">Ops</td>
              <td className="title">Ground Handling</td>
              <td className="title">Fleet Service</td>
            </tr>
            {fsrDetails &&
              fsrDetails?.requestleg_requestLeg.map((L, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td rowSpan={2}>{L?.departure_airport_name ?? `${L?.destination_airport_iata} / ${L?.departure_airport_icao}`} {`(${L?.departure_airport_iata} / ${L?.departure_airport_icao})`}</td>
                    <td rowSpan={2}>{L?.destination_airport_name} {`(${L?.destination_airport_iata} / ${L?.destination_airport_icao})`}</td>
                    <td rowSpan={2}>{Number(L?.fuel_uplift)?.toFixed(2)}</td>
                    <td className="title" rowSpan={2}>At</td>
                    <td className='relative'>
                      <span className='text-info'>Dep</span>
                      {L?.departure_airport_iata}
                    </td>
                    <td>
                      <ul className="no-style-list">
                        {L?.requestlegservice_services_from &&
                          services &&
                          services?.data &&
                          services?.data?.filter(S => L.requestlegservice_services_from.map(L => L.service).includes(S.id) && S.serviceCategory.toLowerCase() === constants.OPSSERVICETYPE)?.length &&
                          services?.data?.filter(S => L.requestlegservice_services_from.map(L => L.service).includes(S.id) && S.serviceCategory.toLowerCase() === constants.OPSSERVICETYPE)?.map((S, index) =>
                            <li key={index}> {`${S.name} (${L.requestlegservice_services_from?.find(s => s?.service === S?.id)?.quantity})`} </li>
                          ) || <li> No Service </li>
                        }
                      </ul>
                    </td>
                    <td>
                      <ul className="no-style-list">
                        {L?.requestlegservice_services_from &&
                          services &&
                          services?.data &&
                          services?.data?.filter(S => L.requestlegservice_services_from.map(L => L.service).includes(S.id) && S.serviceCategory.toLowerCase() === constants.GROUNDSERVICETYPE)?.length &&
                          services?.data?.filter(S => L.requestlegservice_services_from.map(L => L.service).includes(S.id) && S.serviceCategory.toLowerCase() === constants.GROUNDSERVICETYPE)?.map((S, index) =>
                            <li key={index}> {`${S.name} (${L.requestlegservice_services_from?.find(s => s?.service === S?.id)?.quantity})`} </li>
                          ) || <li> No Service </li>
                        }
                      </ul>
                    </td>
                    <td>
                      <ul className="no-style-list">
                        {L?.requestlegservice_services_from &&
                          services &&
                          services?.data &&
                          services?.data?.filter(S => L.requestlegservice_services_from.map(L => L.service).includes(S.id) && S.serviceCategory.toLowerCase() === constants.FLEETSERVICETYPE)?.length &&
                          services?.data?.filter(S => L.requestlegservice_services_from.map(L => L.service).includes(S.id) && S.serviceCategory.toLowerCase() === constants.FLEETSERVICETYPE)?.map((S, index) =>
                            <li key={index}> {`${S.name} (${L.requestlegservice_services_from?.find(s => s?.service === S?.id)?.quantity})`} </li>
                          ) || <li> No Service </li>
                        }
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td className='relative'>
                      <span className='text-info'>Arr</span>
                      {L?.destination_airport_iata}
                    </td>
                    <td>
                      <ul className="no-style-list">
                        {L?.requestlegservice_services_to &&
                          services &&
                          services?.data &&
                          services?.data?.filter(S => L.requestlegservice_services_to.map(L => L.service).includes(S.id) && S.serviceCategory.toLowerCase() === constants.OPSSERVICETYPE)?.length &&
                          services?.data?.filter(S => L.requestlegservice_services_to.map(L => L.service).includes(S.id) && S.serviceCategory.toLowerCase() === constants.OPSSERVICETYPE)?.map((S, index) =>
                            <li key={index}> {`${S.name} (${L.requestlegservice_services_to?.find(s => s?.service === S?.id)?.quantity})`} </li>
                          ) || <li> No Service </li>
                        }
                      </ul>
                    </td>
                    <td>
                      <ul className="no-style-list">
                        {L?.requestlegservice_services_to &&
                          services &&
                          services?.data &&
                          services?.data?.filter(S => L.requestlegservice_services_to.map(L => L.service).includes(S.id) && S.serviceCategory.toLowerCase() === constants.GROUNDSERVICETYPE)?.length &&
                          services?.data?.filter(S => L.requestlegservice_services_to.map(L => L.service).includes(S.id) && S.serviceCategory.toLowerCase() === constants.GROUNDSERVICETYPE)?.map((S, index) =>
                            <li key={index}> {`${S.name} (${L.requestlegservice_services_to?.find(s => s?.service === S?.id)?.quantity})`} </li>
                          ) || <li> No Service </li>
                        }
                      </ul>
                    </td>
                    <td>
                      <ul className="no-style-list">
                        {L?.requestlegservice_services_to &&
                          services &&
                          services?.data &&
                          services?.data?.filter(S => L.requestlegservice_services_to.map(L => L.service).includes(S.id) && S.serviceCategory.toLowerCase() === constants.FLEETSERVICETYPE)?.length &&
                          services?.data?.filter(S => L.requestlegservice_services_to.map(L => L.service).includes(S.id) && S.serviceCategory.toLowerCase() === constants.FLEETSERVICETYPE)?.map((S, index) =>
                            <li key={index}> {`${S.name} (${L.requestlegservice_services_to?.find(s => s?.service === S?.id)?.quantity})`} </li>
                          ) || <li> No Service </li>
                        }
                      </ul>
                    </td>
                  </tr>
                </React.Fragment>
              ))
            }
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );

  const HOTEL_ACCOMODATION_SECTION = () => (
    <React.Fragment>
      <div className="table-heading">HOTEL ACCOMODATION</div>
      <table className="custom-table hotel-data">
        <tbody>
          <tr>
            <td className="title" colSpan="2">Route</td>
            <td className="title"></td>
            <td className="title">
              Total Rooms
            </td>
            <td className="title"></td>
            <td className="title" colSpan="3"></td>
          </tr>
          <tr>
            <td className="title">From</td>
            <td className="title">To</td>
            <td className="title">Hotel Reservation</td>
            <td className="title">#</td>
            <td className="title">Nights</td>
            <td className="title">Crew</td>
            <td className="title">Description</td>
          </tr>
          {fsrDetails &&
            fsrDetails?.requestleg_requestLeg.map((L, index) => (
              <tr
                key={index}
              // className={
              //   L?.services &&
              //     services &&
              //     services?.data &&
              //     services?.data?.filter(S => L.services.map(L => L.service).includes(S.id) && S.serviceCategory.toLowerCase() === constants.HOTELSERVICETYPE).length
              //     ? '' : 'hidden'
              // }
              >

                <td>{L?.departure_airport_name ?? `${L?.destination_airport_iata} / ${L?.departure_airport_icao}`}{`(${L?.departure_airport_iata} / ${L?.departure_airport_icao})`}</td>
                <td>{L?.destination_airport_name}{`(${L?.destination_airport_iata} / ${L?.destination_airport_icao})`}</td>
                <td>{L?.requestleghotel_requestLegHotel?.length ? 'Yes' : 'No'}</td>
                <td className="title">
                  {
                    L?.requestleghotel_requestLegHotel?.reduce((acc, H) => {
                      const lodgers = H?.requestleghotelpax_requestLegHotelPAX?.length || 0;
                      return acc + lodgers;
                    }, 0)
                    || (L?.requestleghotel_requestLegHotel?.length && L?.requestlegcrewmember_crewMembers?.length)
                  }
                </td>
                <td>
                  {L?.requestleghotel_requestLegHotel?.length
                    ? <div className='text-left'>
                      <div><strong>From:</strong>
                        {constants.DATE_FORMAT_REGEX.exec(L?.requestleghotel_requestLegHotel?.[0]?.checkInDateTime)?.[0]}
                        {constants.TIME_FORMAT_REGEX.exec(L?.requestleghotel_requestLegHotel?.[0]?.checkInDateTime)?.[0]?.replace('T', ' ')}
                      </div>
                      <div><strong>To:</strong>
                        {constants.DATE_FORMAT_REGEX.exec(L?.requestleghotel_requestLegHotel?.[0]?.checkOutDateTime)?.[0]}
                        {constants.TIME_FORMAT_REGEX.exec(L?.requestleghotel_requestLegHotel?.[0]?.checkOutDateTime)?.[0]?.replace('T', ' ')}
                      </div>
                      {/* <div><strong>For:</strong> (1) Nights</div> */}
                    </div>
                    : null
                  }
                </td>
                <td>
                  <ul className="no-style-list">
                    {L?.requestleghotel_requestLegHotel?.[0]?.requestleghotelpax_requestLegHotelPAX
                      ?.map(Ldg => Ldg.crewMembers)
                      ?.map(crewId => (
                        crewMembers?.data?.find(C => parseInt(C.id) === parseInt(crewId))?.crewname
                      ))
                      ?.map((name, index) => <li key={index}>{name}</li>)
                    }
                  </ul>
                </td>
                <td><div>{`${L?.requestleghotel_requestLegHotel?.[0]?.description || '-'}`}</div></td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </React.Fragment>
  );

  const CATERING_SECTION = () => (
    <React.Fragment>
      <div className="table-heading">CATERING</div>
      <div className='catering-data'>
        <table className="custom-table ">
          <tbody>
            <tr>
              <td className="title" colSpan="2">Route</td>
              <td className="title" colSpan="2"></td>
            </tr>
            <tr>
              <td className="title">From</td>
              <td className="title">To</td>
              <td className="title">CATERING</td>
              <td className="title"></td>
            </tr>
            {fsrDetails &&
              fsrDetails?.requestleg_requestLeg.map((L, index) => (
                <React.Fragment key={index}>
                  <tr
                  // className={
                  //   L?.services &&
                  //     services &&
                  //     services?.data &&
                  //     services?.data?.filter(S => L.services.map(L => L.service).includes(S.id) && S.serviceCategory.toLowerCase() === constants.CATERINGSERVICETYPE).length
                  //     ? '' : 'hidden'
                  // }
                  >
                    <td rowSpan={2}>{L?.departure_airport_name ?? `${L?.destination_airport_iata} / ${L?.departure_airport_icao}`}{`(${L?.departure_airport_iata} / ${L?.departure_airport_icao})`}</td>
                    <td rowSpan={2}>{L?.destination_airport_name}{`(${L?.destination_airport_iata} / ${L?.destination_airport_icao})`}</td>
                    <td rowSpan={2}>{L?.requestlegcatering_requestLegCatering_from?.length ? 'Yes' : 'No'}</td>
                    <td className='p-0'>
                      <div className='catering-detail'>
                        <strong>Pax #</strong>
                        <div className='text'>
                          {
                            L?.requestlegcatering_requestLegCatering_from?.reduce((acc, C) => {
                              const paxcat = C?.paxcat || 0;
                              return acc + paxcat;
                            }, 0)
                          }
                        </div>
                      </div>
                      <div className='catering-detail'>
                        <strong>Crew #</strong>
                        <div className='text'>                      {
                          L?.requestlegcatering_requestLegCatering_from?.reduce((acc, C) => {
                            const crewcat = C?.crewcat || 0;
                            return acc + crewcat;
                          }, 0)
                        }</div>
                      </div>
                      <div className='catering-detail'>
                        <strong>Catering Type</strong>
                        <div className='text'>{L?.requestlegcatering_requestLegCatering_from?.find(C => C.type_of_catering)?.type_of_catering}</div>
                      </div>
                      <div className='catering-detail'>
                        <strong>Catering Request</strong>
                        <div className='text'>{L?.requestlegcatering_requestLegCatering_from?.find(C => C.description)?.description}</div>
                      </div>
                    </td>
                  </tr>
                  <tr
                  // className={
                  //   L?.services &&
                  //     services &&
                  //     services?.data &&
                  //     services?.data?.filter(S => L.services.map(L => L.service).includes(S.id) && S.serviceCategory.toLowerCase() === constants.CATERINGSERVICETYPE).length
                  //     ? '' : 'hidden'
                  // }
                  >
                    {/* <td className='p-0'>
                      <div className='catering-detail'>
                        <strong>Pax #</strong>
                        <div className='text'>
                          {
                            L?.requestlegcatering_requestLegCatering_to?.reduce((acc, C) => {
                              const paxcat = C?.paxcat || 0;
                              return acc + paxcat;
                            }, 0)
                          }
                        </div>
                      </div>
                      <div className='catering-detail'>
                        <strong>Crew #</strong>
                        <div className='text'>                      {
                          L?.requestlegcatering_requestLegCatering_to?.reduce((acc, C) => {
                            const crewcat = C?.crewcat || 0;
                            return acc + crewcat;
                          }, 0)
                        }</div>
                      </div>
                      <div className='catering-detail'>
                        <strong>Catering Request</strong>
                        <div className='text'>{L?.requestlegcatering_requestLegCatering_to?.find(C => C.description)?.description}</div>
                      </div>
                    </td> */}
                  </tr>
                </React.Fragment>
              ))
            }
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );

  const CREW_TRANSPORT_SECTION = () => (
    <React.Fragment>
      <div className="table-heading">CREW TRANSPORTATION</div>
      <div className='transportation-data'>
        <table className="custom-table">
          <tbody>
            <tr>
              <td className="title" colSpan="2">Route</td>
              <td className="title" colSpan="5"></td>
            </tr>
            <tr>
              <td className="title">From</td>
              <td className="title">To</td>
              {/* <td className="title">Transportation</td> */}
              <td className="title">Crew Transportation</td>
              <td className="title">Hotel/Airport</td>
              <td className="title">Airport/Hotel</td>
            </tr>
            {fsrDetails &&
              fsrDetails?.requestleg_requestLeg.map((L, index) => (
                <tr
                  key={index}
                // className={
                //   L?.services &&
                //     services &&
                //     services?.data &&
                //     services?.data?.filter(S => L.services.map(L => L.service).includes(S.id) && S.serviceCategory.toLowerCase() === constants.HOTELSERVICETYPE).length
                //     ? '' : 'hidden'
                // }
                >
                  <td>{L?.departure_airport_name ?? `${L?.destination_airport_iata} / ${L?.departure_airport_icao}`}{`(${L?.departure_airport_iata} / ${L?.departure_airport_icao})`}</td>
                  <td>{L?.destination_airport_name}{`(${L?.destination_airport_iata} / ${L?.destination_airport_icao})`}</td>
                  {/* <td>{L?.reqlegtransport_requestLegTransport?.length ? 'Yes' : 'No'}</td> */}
                  <td>
                    {L?.reqlegtransport_requestLegTransport?.length &&
                      L?.reqlegtransport_requestLegTransport?.map(((T, index) => (
                        <div key={index}>
                          <div className='text-left'>
                            <div><strong>From:</strong> {`${dayjs(L?.departure_date || ignoreTimeZone(L?.departure_time)).format('DD-MM-YYYY')}`}</div>
                            <div><strong>To:</strong> {`${dayjs(L?.arrival_date || ignoreTimeZone(L?.arrival_time)).format('DD-MM-YYYY')}`}</div>
                            {T.transport_type === "Hotel To Airport" && <div><strong>Hotel Pickup at:</strong> {T?.transport_time}</div>}
                          </div>
                        </div>
                      )))
                      || null
                    }
                  </td>
                  <td>
                    {/* removed the time after changes request from FSR finance team */}
                    {/* {
                    L?.reqlegtransport_requestLegTransport
                      ?.filter(L => (L?.transport_type == "Hotel To Airport"))
                      ?.map((T, index) => <p key={index}> {`At: ${T?.transport_time}`}</p>)
                  } */}
                    {L?.reqlegtransport_requestLegTransport
                      ?.find(L => (L?.transport_type == "Hotel To Airport"))
                      ? 'Yes'
                      : 'No'
                    }
                  </td>
                  <td>
                    {/* removed the time after changes request from FSR finance team */}
                    {/* {
                    L?.reqlegtransport_requestLegTransport
                      ?.filter(L => (L?.transport_type == "Airport to Hotel"))
                      ?.map((T, index) => <p key={index}> {`At: ${T?.transport_time}`}</p>)
                  } */}
                    {L?.reqlegtransport_requestLegTransport
                      ?.find(L => (L?.transport_type == "Airport to Hotel"))
                      ? 'Yes'
                      : 'No'
                    }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );

  const CAR_RENTAL_SECTION = () => (
    <React.Fragment>
      <div className="table-heading">CAR RENTAL</div>
      <table className="custom-table">
        <tbody>
          <tr>
            <td className="title" colSpan="2">Route</td>
            <td className="title" colSpan="3"></td>
          </tr>
          <tr>
            <td className="title">From</td>
            <td className="title">To</td>
            <td className="title">Car Reservation</td>
            <td className="title">Days</td>
            <td className="title">Details</td>
          </tr>
          {fsrDetails &&
            fsrDetails?.requestleg_requestLeg.map((L, index) => (
              <tr
                key={index}
              // className={
              //   L?.services &&
              //     services &&
              //     services?.data &&
              //     services?.data?.filter(S => L.services.map(L => L.service).includes(S.id) && S.serviceCategory.toLowerCase() === constants.HOTELSERVICETYPE).length
              //     ? '' : 'hidden'
              // }
              >
                <td>{L?.departure_airport_name ?? `${L?.destination_airport_iata} / ${L?.departure_airport_icao}`}{`(${L?.departure_airport_iata} / ${L?.departure_airport_icao})`}</td>
                <td>{L?.destination_airport_name}{`(${L?.destination_airport_iata} / ${L?.destination_airport_icao})`}</td>
                <td>{L?.reqlegcarrental_requestLegCarRental?.length ? 'Yes' : 'No'}</td>
                <td className="title">{L?.reqlegcarrental_requestLegCarRental && L?.reqlegcarrental_requestLegCarRental?.[0]?.rental_days}</td>
                <td>{L?.reqlegcarrental_requestLegCarRental && L?.reqlegcarrental_requestLegCarRental?.[0]?.car_rental_type}</td>
                {/* <td>{L?.reqlegcarrental_requestLegCarRental && L?.reqlegcarrental_requestLegCarRental?.[0]?.crewmembers?.length}</td> */}
              </tr>
            ))
          }
        </tbody>
      </table>
    </React.Fragment>
  );

  const CREW_NAME_SECTION = () => (
    <React.Fragment>
      <div className="table-heading">CREW NAME AS FOLLOWS</div>
      <table className="custom-table">
        <tbody>
          {fsrDetails &&
            fsrDetails?.requestleg_requestLeg.map((L, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td className="title" colSpan="6">Route</td>
                </tr>
                <tr>
                  <td colSpan="3">{L?.departure_airport_name}{`(${L?.departure_airport_iata} / ${L?.departure_airport_icao})`}{`(${L?.departure_airport_iata} / ${L?.departure_airport_icao})`}</td>
                  <td colSpan="3">{L?.destination_airport_name}{`(${L?.destination_airport_iata} / ${L?.destination_airport_icao})`}{`(${L?.destination_airport_iata} / ${L?.destination_airport_icao})`}</td>
                </tr>
                <tr>
                  <td className="title">Duty</td>
                  <td className="title">Name</td>
                  <td className="title">Saudia ID</td>
                  <td className="title">Nationality</td>
                  <td className="title">ID/Passport#</td>
                  <td className="title">License#</td>
                </tr>
                {
                  crewMembers?.data?.sort((a, b) => (
                    a?.crewtitle?.toLowerCase()?.startsWith("c") && !b?.crewtitle?.toLowerCase()?.startsWith("c"))
                    ? -1
                    : (b?.crewtitle?.toLowerCase()?.startsWith("c") && !a?.crewtitle?.toLowerCase()?.startsWith("c"))
                      ? 1
                      : a?.crewtitle?.localeCompare(b?.crewtitle))?.filter((crew) => (
                        L?.requestlegcrewmember_crewMembers?.find(C => C?.crewmember === crew?.id)
                      ))?.map((crew, index) => (
                        <tr key={index}>
                          <td>{crew?.crewtitle}</td>
                          <td>{crew?.crewname}</td>
                          <td>{crew?.nationality?.toLowerCase()?.indexOf("saudi") > -1 ? "Yes" : "No"}</td>
                          <td>{crew?.nationality}</td>
                          <td>{crew?.passport}</td>
                          <td>{crew?.license}</td>
                        </tr>
                      ))
                }
                {/* Un sorted Crew members */}
                {/* {L?.requestlegcrewmember_crewMembers?.map((crew, index) => (
                  <tr key={index}>
                    <td>{crewMembers?.data?.find(C => C.id === crew.crewmember)?.crewtitle}</td>
                    <td>{crewMembers?.data?.find(C => C.id === crew.crewmember)?.crewname}</td>
                    <td>{crewMembers?.data?.find(C => C.id === crew.crewmember)?.nationality?.toLowerCase()?.indexOf("saudi") > -1 ? "Yes" : "No"}</td>
                    <td>{crewMembers?.data?.find(C => C.id === crew.crewmember)?.nationality}</td>
                    <td>{crewMembers?.data?.find(C => C.id === crew.crewmember)?.passport}</td>
                    <td>{crewMembers?.data?.find(C => C.id === crew.crewmember)?.license}</td>
                  </tr>
                ))} */}
              </React.Fragment>
            ))
          }
        </tbody>
      </table>
    </React.Fragment>
  );

  const DOWNLOAD_PDF_BUTTON = () => (
    <button
      onClick={handleShowPDFOptions}
      className={`${props?.fsrData ? 'hidden' : ''} pdf-download-button fixed bottom-0 right-0 w3-btn bg-primary-blue w3-text-white shadow-lg w3-circle`}
    >
      <Save className='h-10 w-10' />
    </button>
  );

  const REQUEST_COMMENT_SECTION = () => (
    <React.Fragment>
      {props?.fsrData
        ? <div>
          <label> Comments </label>
          <input
            type='text'
            name='comments'
            className='w3-input w3-border w3-round'
            defaultValue={fsrDetails?.comments || ''}
            onChange={props?.changeHandler}
          />
          <div className='small-top-margin'></div>
          <label> Contact Person </label>
          <input
            type='text'
            name='contact_person_details'
            className={`w3-input w3-border w3-round ${props?.isFieldInvalid?.contact_person_details ? 'error-field' : ''}`}
            defaultValue={fsrDetails?.contact_person_details || ''}
            onChange={props?.changeHandler}
          />
          <div className='small-top-margin'></div>
          <label> Submit by </label>
          <input
            type='text'
            name='sendby'
            className={`w3-input w3-border w3-round ${props?.isFieldInvalid?.sendby ? 'error-field' : ''}`}
            defaultValue={fsrDetails?.sendby || ''}
            onChange={props?.changeHandler}
          />
        </div>
        : <div>
          <div className="table-heading">Comments</div>
          <div className='content-details custom-table'>
            <table className="custom-table">
              <tbody><tr><td>{fsrDetails?.comments}</td></tr></tbody>
            </table>
          </div>
          <div className="table-heading">Contact Person</div>
          <div className='content-details custom-table'>
            <table className="custom-table">
              <tbody><tr><td>{fsrDetails?.contact_person_details}</td></tr></tbody>
            </table>
          </div>
          <div className="table-heading">Submit by</div>
          <div className='content-details custom-table'>
            <table className="custom-table">
              <tbody><tr><td>{fsrDetails?.sendby}</td></tr></tbody>
            </table>
          </div>
        </div>}
    </React.Fragment>
  );

  const CREW_PERMIT_DESCRIPTION = () => (
    <React.Fragment>
      <div className="table-heading">Permit Descriptions</div>
      <table className="custom-table">
        <tbody>
          <tr>
            <td className="title" colSpan="2">Route</td>
            <td className="title" colSpan="3"></td>
          </tr>
          <tr>
            <td className="title">From</td>
            <td className="title">To</td>
            <td className="title">Description</td>
          </tr>
          {fsrDetails &&
            fsrDetails?.requestleg_requestLeg.map((L, index) => (
              <tr
                key={index}
              // className={
              //   L?.services &&
              //     services &&
              //     services?.data &&
              //     services?.data?.filter(S => L.services.map(L => L.service).includes(S.id) && S.serviceCategory.toLowerCase() === constants.HOTELSERVICETYPE).length
              //     ? '' : 'hidden'
              // }
              >
                <td>{L?.departure_airport_name ?? `${L?.destination_airport_iata} / ${L?.departure_airport_icao}`}{`(${L?.departure_airport_iata} / ${L?.departure_airport_icao})`}</td>
                <td>{L?.destination_airport_name}{`(${L?.destination_airport_iata} / ${L?.destination_airport_icao})`}</td>
                <td>{L?.permit_description}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </React.Fragment>
  );

  // console.log(fsrDetails);

  return (
    <React.Fragment>
      {PDF_OPTIONS_MODAL()}
      <div className='request-block'>
        {REQUEST_DETAILS_SECTION()}
        {SERVICES_REQUIRED_SECTION()}
        {HOTEL_ACCOMODATION_SECTION()}
        {CATERING_SECTION()}
        {CREW_TRANSPORT_SECTION()}
        {/* {CAR_RENTAL_SECTION()} */}
        {CREW_NAME_SECTION()}
        {CREW_PERMIT_DESCRIPTION()}
        {REQUEST_COMMENT_SECTION()}
        {DOWNLOAD_PDF_BUTTON()}
      </div>
    </React.Fragment>
  )
}

export default RequestView;