// Packages
import React from 'react';

// Utils
import { Loader, Warn } from '../../../../components';

export default function StandAloneHotelbillsTable({
  items,  
  onAmountChange,
  onAddMarkDelete,  
  markedDeleteItems,
  isLoading,  
}) {  

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const BILLS_LIST = () => (
    <table className='w3-table w3-striped'>
      <thead>
        <tr>
          {/* <th style={{ width: '110px' }}> Mark Delete </th> */}
          <th> Bill Id </th>
          <th> Hotel Id </th>
          <th> Costplus Id </th>          
          <th> Service </th>
          <th> Amount </th>
        </tr>
      </thead>
      <tbody>
        {!items
          ? <tr><td colSpan={5}> No data found</td></tr>
          : null
        }
        {
          items &&
          items?.map((item, index) => (
            <tr key={index}>
              {/* <td style={{ width: '110px' }}>
                {item?.cost_item_id
                  ? <input
                    type='checkbox'
                    checked={markedDeleteItems?.includes(item?.cost_item_id)}
                    onChange={e => onAddMarkDelete(item, e)}
                  />
                  : null
                }
              </td> */}
              <td>
                {item?.bill_id
                  ?
                  <div> <span>{item?.bill_id}</span></div>
                  : <div className='w3-text-grey flex items-center'>
                    <span> Null </span>
                    <span className="cursor-pointer small-left-margin w3-tooltip">
                      <span className='absolute bills-list-tooltip w3-text w3-tag w-32 w3-small'>
                        This entry is not present in Odoo bills
                      </span>
                      <Warn className='h-5 w-5' style={{ width: '1.25rem', height: '1.25rem' }} />
                    </span>
                  </div>
                }
              </td>
              <td>
                <a className='cursor-pointer text-primary-blue w3-hover-text-blue' href={`/hotel-reservations/view/${item?.hotel_id}`} target="_blank"> {item?.hotel_id} </a>
              </td>
              <td>
                {item?.cost_plus_invoice_id
                  ? <a className='cursor-pointer text-primary-blue w3-hover-text-blue' href={`/costplus-invoices/view/${item?.cost_plus_invoice_id}`} target="_blank"> {item?.cost_plus_invoice_id} </a>
                  : <div className='w3-text-grey flex items-center'>
                    <span> Null </span>
                    <span className="cursor-pointer small-left-margin w3-tooltip">
                      <span className='absolute bills-list-tooltip w3-text w3-tag w-32 w3-small'>
                        This entry didn't match in any costplus invoice
                      </span>
                      <Warn className='h-5 w-5' style={{ width: '1.25rem', height: '1.25rem' }} />
                    </span>
                  </div>
                }
              </td>             
              <td> HOTEL </td>
              <td>
                {Number(parseInt(item?.bill_id)) && Number(item?.cost_plus_invoice_id)
                  ? <input type="text" onBlur={e => onAmountChange(item, e)} min={0} defaultValue={item?.total_cost} className='w-24 no-focus' />
                  : <span className='w3-pale-red w-full h-full p-1'>{parseFloat(item?.total_cost || 0)?.toFixed(2)}</span>
                }
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );

  return (
    <div id="bills-list">
      {isLoading ? LOADER() : BILLS_LIST()}
    </div>
  );
};