import { constants } from "../utils";

const FetchCostItemsListRequest = (token, ContractId, data) => {
  var requestHeaders = new Headers();
  requestHeaders.append("Authorization", `Bearer ${token}`);
  requestHeaders.append("Content-Type", "application/json");
  return fetch(`${constants.BASEURL}/api/costplusinvoice/get_request_with_cost_items/${ContractId}/`, {
    method: "POST",
    body: data,
    headers: requestHeaders,
    redirect: "follow",
  });
};

export default FetchCostItemsListRequest;
