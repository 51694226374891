import { constants } from "../utils";

const InvoiceReportVersionCreateRequest = (token, data) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`); 
    requestHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: 'POST',
      headers: requestHeaders,
      body: data,
      redirect: 'follow'
    };

    return fetch(`${constants.BASEURL}/api/invoicereportversion/`, requestOptions);
};

export default InvoiceReportVersionCreateRequest;
