// Packages
import React from 'react';


// Utils
import { InvoicePdfViewer } from './components';

function InvoicesPdfView() {

  // *********** Render Functions ***********

  const INVOICE_PDF_VIEWER = () => (
    <div className="w-full">
      <InvoicePdfViewer />
    </div>
  );

  const CONTENT = () => (
    <div className='page-content w3-white h-full relative'>
      <div className='py-2'>
        {INVOICE_PDF_VIEWER()}
      </div>
    </div>
  );

  return (
    <div>
      {CONTENT()}
    </div>
  )
}

export default InvoicesPdfView;